import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ref, computed } from 'vue'
import { useDatabase } from './useDatabase.js'

dayjs.extend(utc)

const { get } = useDatabase()

const oldEvents = localStorage.getItem('events')
const isLoading = ref(true)
const localEvents = ref(oldEvents == 'undefined' ? [] : (JSON.parse(oldEvents) || []))

const events = computed(() => {
    return localEvents.value.map(e => {
        let globalStart = e.start?.length != 16
        let globalEnd = e.end?.length != 16

        let start = e.start
        let end = e.end

        if (globalStart && start.length == '10') start = `${e.start} 10:30 UTC+8` // end of maintenance
        if (globalEnd && end.length == '10') end = `${e.end} 05:30 UTC+8` // start of maintenance

        return {
            ...e, globalStart, globalEnd,
            key: `event-${e.id}`,
            start: start && dayjs.utc(start),
            end: end && dayjs.utc(end),
        }
    })
    // .sort((a, b) => {
    //     // if (!a.start) return 1
    //     // if (!b.start) return -1

    //     // if (a.start.unix() == b.start.unix()) return 0

    //     return a.start > b.start ? 1 : -1
    // })
})

const computedEvents = (time, serverTime) => {
    return computed(() => {
        return events.value.map(e => {
            return {
                ...e,
                started: e.start && (e.globalStart ? time : serverTime).diff(e.start) >= 0,
                ended: e.end && (e.globalEnd ? time : serverTime).diff(e.end) > 0,
            }
        }).filter(e => !e.ended)
        .sort((a, b) => {
            if (a.characters && !b.characters) return -1
            if (!a.characters && b.characters) return 1
            return ((a.started && a.end) || a.start) > ((b.started && b.end) || b.start) ? 1 : -1
        })
    })
}

export function useEvents (table) {
    return {
        isLoading,
        // events,
        computedEvents,
        update: async () => {
            localEvents.value = await get(table)

            if (oldEvents != JSON.stringify(localEvents.value)) {
                localStorage.setItem('events', JSON.stringify(localEvents.value))
            }

            isLoading.value = false
        },
    }
}
