<template>
    <transition
        enter-from-class="opacity-0 transform translate-y-6"
        enter-to-class="opacity-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0 transform translate-y-6"
    >
        <div v-if="toast"
            class="transition-all duration-200 fixed bottom-0 right-0 z-[9999] bg-black text-white py-2 font-semibold px-4 rounded-lg m-2"
            v-html="toast"
        >
        </div>
    </transition>
</template>

<script>
export default {
    data () {
        return {
            timeout: null,
        }
    },

    watch: {
        toast () {
            if (this.timeout) clearTimeout(this.timeout)

            this.timeout = setTimeout(() => {
                this.toast = null
            }, 3000)
        }
    }
}
</script>
