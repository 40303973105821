<template>
    <button
        class="w-full flex items-center justify-center h-12 bg-gray-800 pl-2 pr-3 rounded-lg shadow hover:bg-gray-700 transition-all mt-2"
        @click="isDonateModalVisible = true"
        >
        <img :src="`${url}img/stickers/1-mora.png`" class="h-12 mr-3"/>
        <p class="font-semibold text-lg">{{ $t('donate') }}</p>
    </button>

    <Dialog :draggable="false" :header="$t('donate')" position="center" v-model:visible="isDonateModalVisible" modal style="max-width: 440px;" dismissable-mask>
        <template v-if="$i18n.locale === 'pt'">
            <div>Você pode ajudar a contribuir com o projeto doando qualquer valor.</div>
            <div class="mt-2">
                <a class="text-purple-300 underline font-semibold" target="_blank" href="https://www.paypal.com/donate?hosted_button_id=FTU4ZPEXE9SK8">Doe pelo PayPal</a>
                <p class="mb-2">ou</p>
                <a href="https://www.buymeacoffee.com/seelie">
                    <img src="https://img.buymeacoffee.com/button-api/?text=Me compra um cafezinho&emoji=&slug=seelie&button_colour=BD5FFF&font_colour=ffffff&font_family=Lato&outline_colour=000000&coffee_colour=FFDD00" />
                </a>
            </div>
            <div class="my-2">Se você usa algum bloqueador de anúncios, por favor considere desabilitá-lo no site.</div>
            💗
        </template>
        <template v-else>
            <div>You can help with this project by donating any amount.</div>
            <div class="mt-2">
                <a class="text-purple-300 underline font-semibold" target="_blank" href="https://www.paypal.com/donate?hosted_button_id=FTU4ZPEXE9SK8">Donate via PayPal</a>
                <p class="mb-2">or</p>
                <a href="https://www.buymeacoffee.com/seelie">
                    <img src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=seelie&button_colour=BD5FFF&font_colour=ffffff&font_family=Lato&outline_colour=000000&coffee_colour=FFDD00" />
                </a>
            </div>
            <div class="my-2">Your help is greatly appreciated.</div>
            <div class="my-2">If you use an adblocker, please consider whitelisting us.</div>
            💗
        </template>
    </Dialog>

    <div class="mt-2 flex items-center justify-center w-full rounded-lg overflow-hidden h-10">
        <a
            class="w-1/2 py-2 h-full flex items-center justify-center shadow bg-gray-800 hover:bg-gray-700 transition-all"
            target="_blank"
            href="https://discord.com/invite/rwcF5WQD7c"
            v-tippy.arrow="'Discord'"
        >
            <!-- <discord-icon class="h-5"/> -->
            <img :src="$url('icons/discord.svg')" class="w-5">
        </a>
        <a
            class="w-1/2 py-2 h-full flex items-center justify-center shadow bg-gray-800 hover:bg-gray-700 transition-all"
            target="_blank"
            href="https://www.youtube.com/@meseelie"
            v-tippy.arrow="'YouTube'"
        >
            <!-- <youtube-icon class="h-5"/> -->
            <img :src="$url('icons/youtube.svg')" class="w-5">
        </a>
        <!-- <a
            class="w-1/2 py-2 h-full flex items-center justify-center shadow bg-gray-800 hover:bg-gray-700 transition-all"
            target="_blank"
            :href="$i18n.locale === 'pt' ? 'mailto:contato@seelie.me' : 'mailto:contact@seelie.me'"
        >
            <mail-icon class="h-6"/>
        </a> -->
    </div>
</template>

<script>
import Dialog from 'primevue/dialog'

export default {
    components: { Dialog }
}
</script>
