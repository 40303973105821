<template>
    <div class="flex items-center my-2" :class="divider && 'md:border-r md:pr-3 md:mr-3 border-gray-600/60'">
        <button
            v-for="(option, i) in options"
            :key="`filter-${type}-${option}`"
            class="w-9 h-9 flex items-center justify-center focus:outline-none rounded-md hover:bg-gray-800/50 border-purple-500/70 transition-colors"
            :class="(isActive || activeItem == option) && 'border bg-gray-800'"
            v-tippy.arrow="$te(option) && $t(option)"
            @click="$emit('filter', option)"
        >
            <img :src="`${url}img/${type}/${option}.png`" class="w-7" v-if="type" :class="iconClass">

            <component :is="icon" class="h-6" :class="colors?.length ? colors[i] : 'text-gray-400'" v-else/>
        </button>
    </div>
</template>

<script>
export default {
    props: {
        type: String,
        activeItem: [String, Number],
        isActive: Boolean,
        options: Array,
        divider: Boolean,
        icon: String,
        colors: Array,
        iconClass: String
    }
}
</script>
