<template>
    <slot :open-modal="() => isModalVisible = true"></slot>

    <Dialog :draggable="false" position="top" v-model:visible="isModalVisible" modal style="width: 400px;" dismissable-mask :header="$t('planner_settings')">

        <div class="flex items-center">
            <div>
                <p class="text-sm font-semibold ml-px">{{ $t('ar') }}</p>
                <input class="bg-gray-600 rounded-md" type="number" min="1" max="60" v-model="ar">
            </div>

            <div class="ml-2">
                <p class="text-sm font-semibold ml-px">{{ $t('wl') }}</p>
                <!-- <input class="bg-gray-600 rounded-md" type="number" min="0" max="8" v-model="wl"> -->
                <select class="bg-gray-600 rounded-md" v-model="wl">
                    <option v-for="i in 10" :key="`wl-${i-1}`" :value="i-1">{{ i-1 }}</option>
                </select>
            </div>

            <div class="ml-2">
                <p class="text-sm font-semibold ml-px">{{ $t('server') }}</p>
                <select class="bg-gray-600 rounded-md" v-model="server">
                    <option value="america">{{ $t('america') }}</option>
                    <option value="europe">{{ $t('europe') }}</option>
                    <option value="asia">{{ $t('asia') }}</option>
                </select>
            </div>

            <div class="ml-2">
                <div class="text-sm font-semibold ml-px">{{ $t('gender') }}</div>
                <div class="flex items-center justify-center rounded-md overflow-hidden text-sm font-semibold h-10 border border-gray-500">
                    <button
                        class="w-1/2 p-2 h-full flex items-center justify-center"
                        :class="gender == 'male' ? 'bg-teal-500/60' : 'bg-gray-600'"
                        @click="gender = 'male'"
                    >
                        <male-icon class="h-4"/>
                    </button>
                    <button
                        class="w-1/2 p-2 h-full flex items-center justify-center"
                        :class="gender == 'female' ? 'bg-teal-500/60' : 'bg-gray-600'"
                        @click="gender = 'female'"
                    >
                        <female-icon class="h-5"/>
                    </button>
                </div>
            </div>
        </div>

        <switch-button v-model="keepItemOpen" :label="$t('keep_item_open')" class="mt-4"/>

        <switch-button v-model="showAddInput" class="mt-2">
            <div class="flex items-center">
                {{ $t('show_additional_inputs') }}
                <question-mark-circle-icon class="w-5 ml-1" v-tippy="`<div class=\'text-xs\'>${$t('show_additional_inputs_explanation')}</div>`"/>
            </div>
        </switch-button>

        <div class="flex items-center mt-4" v-if="showAddInput">
            <label for="tabOrder" class="font-semibold flex-shrink-0">{{ $t('tab_order') }}</label>
            <question-mark-circle-icon class="w-5 ml-1 flex-shrink-0" v-tippy="`<div class=\'text-xs\'>${$t('tab_order_explanation')}</div>`"/>
            <select id="tabOrder" v-model="tabOrder" class="ml-2 bg-gray-700 rounded-lg w-full">
                <option value="alternating">{{ $t('alternating') }}</option>
                <option value="sequential_inv">{{ $t('sequential_inv') }}</option>
                <option value="sequential_add">{{ $t('sequential_add') }}</option>
            </select>
        </div>

    </Dialog>

</template>

<script>
import Dialog from 'primevue/dialog'
import SwitchButton from './SwitchButton.vue'

export default {
    components: { Dialog, SwitchButton },

    data () {
        return {
            isModalVisible: false,
        }
    },

    computed: {
        keepItemOpen: {
            get () { return this.settings.planner_keep_item_open },
            set (value) { this.setSetting('planner_keep_item_open', value) }
        },

        showAddInput: {
            get () { return this.settings.planner_show_add_input },
            set (value) { this.setSetting('planner_show_add_input', value) }
        },

        tabOrder: {
            get () { return this.settings.planner_tab_order },
            set (value) { this.setSetting('planner_tab_order', value) }
        },
    },
}
</script>
