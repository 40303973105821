<template>
    <div class="text-white">
        <h1 class="text-xl font-bold">{{ $t('weapons') }}</h1>
        <div class="mt-4 flex items-center">
            <div class="sm:w-32">
                <weapons-modal>
                    <template v-slot:trigger>
                        <div class="bg-pink-600/60 hover:bg-pink-600 font-semibold text-sm rounded-lg py-2 px-3 shadow hover:shadow-lg w-full sm:w-32 transition-all">
                            {{ $t('add') }}
                        </div>
                    </template>
                </weapons-modal>
            </div>
        </div>

        <div class="mt-4">
            <div class="font-semibold uppercase text-sm mb-1">{{ $t('filter') }}</div>

            <div class="glass rounded-lg px-3 inline-flex items-center justify-start flex-col md:flex-row">
                <object-filter
                    icon="star-icon"
                    :colors="['text-legendary-400','text-epic-400', 'text-rare-400']"
                    :options="[5, 4, 3]"
                    @filter="v => filter('tier', v)"
                    :active-item="tierFilter"
                    divider
                />
                <object-filter
                    type="weapons/types"
                    :options="weaponTypes"
                    @filter="v => filter('type', v)"
                    :active-item="typeFilter"
                    divider
                />
                <object-filter
                    type="general"
                    :options="['character']"
                    @filter="showCharacters = !showCharacters"
                    :is-active="showCharacters"
                    icon-class="p-0.5 opacity-80"
                    divider
                />
                <object-filter
                    icon="eye-icon"
                    :options="['toggle_inactive']"
                    @filter="hideInactive = !hideInactive"
                    :is-active="!hideInactive"
                    divider
                />
                <div class="relative flex items-center mb-2 md:mb-0">
                    <input type="text" :placeholder="$t('search')"
                        class="bg-gray-700/40 border-none rounded-md w-full max-w-64 pr-8" v-model="search" @keyup.esc="search = ''"
                    >
                    <button v-if="search.trim().length" class="absolute right-0 mr-2" @click="search = ''">
                        <x-icon class="w-5 h-5"/>
                    </button>
                </div>
            </div>

            <ad class="mt-5"/>

            <div v-if="hasAny" class="mt-4 flex flex-wrap -mx-2 sm:-mx-3 items-stretch justify-center sm:justify-start">
                <div
                    v-show="search || !((hideInactive && inactive[weapon.goal?.character || weapon.goal?.id || weapon.key]) || (!showCharacters && weapon.goal?.character))"
                    v-for="weapon in filteredWeapons"
                    :key="`weapon-goal-${weapon.goal?.id || weapon.key}`"
                    class="m-2 sm:m-3"
                >
                    <weapons-modal :goal="weapon.goal">
                        <template v-slot:trigger>
                            <weapon-card :selected="weapon.key" :weapon="weapon" :class="{ 'opacity-50 filter-grayscale': inactive[weapon.goal?.character || weapon.goal?.id || weapon.key] }"/>
                        </template>
                    </weapons-modal>
                </div>
            </div>

            <div v-else class="font-semibold text-xl mt-4 text-gray-200 opacity-75">
                {{ $t('no_weapons') }}
            </div>
        </div>
    </div>

    <ad class="mt-5" bottom v-if="filteredWeapons.length >= 4"/>
</template>

<script>
import Ad from '@/components/Ad.vue'
import ObjectFilter from '@/common/components/ObjectFilter.vue'
import WeaponsModal from '@/components/WeaponsModal.vue'
import WeaponCard from '@/components/WeaponCard.vue'

export default {
    components: {
        Ad, ObjectFilter, WeaponsModal, WeaponCard
    },

    data () {
        return {
            search: '',
            tierFilter: null,
            typeFilter: null,
            hideInactive: JSON.parse(localStorage.getItem('weaponsHideInactive')) || false,
            showCharacters: JSON.parse(localStorage.getItem('weaponsShowCharacters')) || false
        }
    },

    watch: {
        hideInactive (value) { localStorage.setItem('weaponsHideInactive', value) },
        showCharacters (value) { localStorage.setItem('weaponsShowCharacters', value) },
    },

    computed: {
        filteredWeapons () {
            return this.goals.filter(g => g.type === 'weapon')
                .map(g => {
                    let weapon = this.weapons[g.weapon] || this.customs[g.weapon]
                    if (!weapon) return

                    return {
                        goal: g,
                        key: g.weapon,
                        name: weapon.name || this.$t(g.weapon),
                        ...(weapon),
                    }
                }).filter(w => {
                    return w
                        && (!this.tierFilter || w.tier === this.tierFilter)
                        && (!this.typeFilter || w.type === this.typeFilter)
                        && (!this.search || w.name.toLowerCase().includes(this.search.toLowerCase()))
                }).sort((a, b) => a.name.localeCompare(b.name))
        },

        hasAny () {
            return this.filteredWeapons.filter(w => {
                return this.search ||
                    !(
                        ( this.hideInactive && this.inactive[w.goal?.character || w.goal?.id || w.key] ) ||
                        ( !this.showCharacters && w.goal?.character )
                    )
            }).length
        }
    },

    methods: {
        filter (type, value) {
            if (this[`${type}Filter`] === value) return this[`${type}Filter`] = null
            return this[`${type}Filter`] = value
        }
    }
}
</script>
