<template>
    <div class="text-white">

        <h1 class="text-xl font-bold">{{ $t('settings') }}</h1>

        <div class="bg-gray-700 p-3 rounded-md mt-4">
            <p>{{ $t('more_than_one_account') }}</p>
            <p class="font-semibold">{{ $t('importing_warning') }}</p>

            <div class="flex items-center justify-between mt-3 flex-wrap gap-y-2">
                <div class="flex items-center flex-wrap gap-y-2">
                    <button class="bg-gray-600 shadow rounded-md px-2 lg:pr-3 py-2 mr-2 flex items-center justify-center" @click="addAccount">
                        <plus-icon class="w-6"/>
                        <span class="hidden lg:block font-semibold text-sm leading-none">{{ $t('add') }}</span>
                    </button>

                    <select class="bg-gray-600 rounded-md mr-2" :value="account" @change="setAccount">
                        <option value="main">{{ settings.account_names.main || $t('main') }}</option>
                        <option :value="acc" v-for="acc in accounts" :key="`option-${acc}`">
                            {{ settings.account_names[acc] || `${$t('account')} ${acc.split('-')[1]}` }}
                        </option>
                    </select>

                    <button class="bg-gray-600 shadow rounded-md px-2 lg:pr-3 py-2 mr-2 flex items-center justify-center" @click="renameModal = true">
                        <pencil-alt-icon class="w-5 h-6 lg:mr-1"/>
                        <span class="hidden lg:block font-semibold text-sm leading-none">{{ $t('rename') }}</span>
                    </button>

                    <Dialog :show-header="false" v-model:visible="renameModal" modal dismissable-mask>
                        <form class="mt-8" @submit.prevent="saveName">
                            <input
                                :value="settings.account_names[account]" autofocus
                                @input="nameChanged"
                                class="bg-gray-700 border border-gray-600 p-2 focus:outline-none rounded-md"
                            />
                            <button class="bg-gray-600 shadow rounded-md py-2 px-3 ml-2">
                                {{ $t('save') }}
                            </button>
                        </form>
                    </Dialog>

                    <button class="bg-red-700 shadow rounded-md px-2 lg:pr-3 py-2 mr-2 flex items-center justify-center"
                        :disabled="account == 'main'"
                        :class="{ 'opacity-50 pointer-events-none': account == 'main' }"
                        @click="deleteModal = true"
                    >
                        <trash-icon class="w-5 h-6 lg:mr-1"/>
                        <span class="hidden lg:block font-semibold text-sm leading-none">{{ $t('delete') }}</span>
                    </button>
                </div>

                <Dialog :show-header="false" v-model:visible="deleteModal" modal dismissable-mask>
                    <p class="mt-8">
                        {{ $t('delete_confirmation') }}
                    </p>
                    <div class="flex items-center justify-end mt-3">
                        <button class="bg-gray-700 shadow rounded-md py-2 px-3" @click="deleteModal = false">
                            {{ $t('cancel') }}
                        </button>
                        <button class="bg-red-700 shadow rounded-md py-2 px-3 ml-2 font-semibold" @click="deleteAccount">
                            {{ $t('delete') }}
                        </button>
                    </div>
                </Dialog>

                <div class="flex items-center justify-end">
                    <button class="bg-gray-600 shadow rounded-md py-2 px-3 mr-2" @click="download">{{ $t('export_account') }}</button>
                    <button class="bg-gray-600 shadow rounded-md py-2 px-3" @click="clickUpload">{{ $t('import_account') }}</button>
                    <input type="file" ref="file" class="hidden" @change="upload" accept="application/JSON"/>
                </div>
            </div>

            <div class="mt-2 text-red-400 font-semibold leading-tight" v-if="errorMsg">
                {{ errorMsg }}
            </div>
            <div class="mt-2 text-green-500 font-semibold leading-tight" v-if="successMsg">
                {{ successMsg }}
            </div>
        </div>

        <div class="bg-gray-700 p-3 rounded-md mt-3">
            <p class="uppercase font-semibold text-sm">{{ $t('account_settings') }}</p>

            <div class="flex items-center mt-2">
                <div>
                    <p class="text-sm font-semibold ml-px">{{ $t('ar') }}</p>
                    <input class="bg-gray-600 rounded-md" type="number" min="1" max="60" v-model="ar">
                </div>

                <div class="ml-3">
                    <p class="text-sm font-semibold ml-px">{{ $t('wl') }}</p>
                    <!-- <input class="bg-gray-600 rounded-md" type="number" min="0" max="8" v-model="wl"> -->
                    <select class="bg-gray-600 rounded-md" v-model="wl">
                        <option v-for="i in 10" :key="`wl-${i-1}`" :value="i-1">{{ i-1 }}</option>
                    </select>
                </div>

                <div class="ml-3">
                    <p class="text-sm font-semibold ml-px">{{ $t('server') }}</p>
                    <select class="bg-gray-600 rounded-md" v-model="server">
                        <option value="america">{{ $t('america') }}</option>
                        <option value="europe">{{ $t('europe') }}</option>
                        <option value="asia">{{ $t('asia') }}</option>
                    </select>
                </div>

                <div class="ml-3">
                    <div class="text-sm font-semibold ml-px">{{ $t('gender') }}</div>
                    <div class="flex items-center justify-center rounded-md overflow-hidden text-sm font-semibold h-10 border border-gray-500">
                        <button
                            class="w-1/2 p-2 h-full flex items-center justify-center"
                            :class="gender == 'male' ? 'bg-teal-500/60' : 'bg-gray-600'"
                            @click="gender = 'male'"
                        >
                            <male-icon class="h-4"/>
                        </button>
                        <button
                            class="w-1/2 p-2 h-full flex items-center justify-center"
                            :class="gender == 'female' ? 'bg-teal-500/60' : 'bg-gray-600'"
                            @click="gender = 'female'"
                        >
                            <female-icon class="h-5"/>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-gray-700 p-3 rounded-md mt-3">
            <p class="uppercase font-semibold text-sm">{{ $t('planner_settings') }}</p>

            <switch-button v-model="keepItemOpen" :label="$t('keep_item_open')" class="mt-2"/>

            <switch-button v-model="showAddInput" class="mt-2">
                <div class="flex items-center">
                    {{ $t('show_additional_inputs') }}
                    <question-mark-circle-icon class="w-5 ml-1" v-tippy="`<div class=\'text-xs\'>${$t('show_additional_inputs_explanation')}</div>`"/>
                </div>
            </switch-button>

            <div class="flex items-center mt-4" v-if="showAddInput">
                <label for="tabOrder" class="font-semibold">{{ $t('tab_order') }}</label>
                <select id="tabOrder" v-model="tabOrder" class="ml-2 bg-gray-700 rounded-lg">
                    <option value="alternating">{{ $t('alternating') }}</option>
                    <option value="sequential_inv">{{ $t('sequential_inv') }}</option>
                    <option value="sequential_add">{{ $t('sequential_add') }}</option>
                </select>
            </div>
        </div>

        <div class="bg-gray-700 p-3 rounded-md mt-3">
            <p class="uppercase font-semibold text-sm">{{ $t('other_settings') }}</p>

            <div class="flex items-center mt-2">
                <div >
                    <p class="text-sm font-semibold ml-px">{{ $t('hour_format') }}</p>
                    <select class="bg-gray-600 rounded-md" v-model="hourFormat">
                        <option :value="24">24 {{ $t('hours') }}</option>
                        <option :value="12">12 {{ $t('hours') }}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="bg-gray-700 p-3 rounded-md mt-3">
            <span v-html="$t('sync_explanation')"/>

            <button @click="login" v-if="!driveIsSignedIn" class="flex items-center shadow bg-gray-600 py-2 px-3 rounded-md mt-2 disabled:opacity-50 disabled:pointer-events-none" :disabled="driveIsLoading">
                <svg class="w-5 mr-2" fill="white" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M4.433 22.396l4-6.929H24l-4 6.929H4.433zm3.566-6.929l-3.998 6.929L0 15.467 7.785 1.98l3.999 6.931-3.785 6.556zm15.784-.375h-7.999L7.999 1.605h8.002l7.785 13.486h-.003z"/></svg>
                <span class="flex-shrink-0 font-semibold">{{ $t('sign_in_google_drive') }}</span>
            </button>

            <button @click="logout" v-else class="flex items-center shadow bg-gray-600 py-2 px-3 rounded-md mt-2">
                <svg class="w-5 mr-2" fill="white" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M4.433 22.396l4-6.929H24l-4 6.929H4.433zm3.566-6.929l-3.998 6.929L0 15.467 7.785 1.98l3.999 6.931-3.785 6.556zm15.784-.375h-7.999L7.999 1.605h8.002l7.785 13.486h-.003z"/></svg>
                <span class="flex-shrink-0 font-semibold">{{ $t('sign_out_google_drive') }}</span>
            </button>

            <div class="mt-2" v-if="driveIsSignedIn && userEmail">
                Email: <span class="font-semibold">{{ userEmail }}</span>
            </div>

            <div class="mt-2" v-if="driveIsSignedIn">
                {{ $t('sync_status') }}: <span :class="syncStatus.class" class="font-semibold">{{ syncStatus.text }}</span>
            </div>

            <div v-if="driveIsSignedIn && remoteTime">{{ $t('last_sync') }}: {{ remoteTime.format('DD MMM YYYY, HH:mm:ss') }}</div>

            <switch-button v-model="autoSync" class="mt-2" v-if="driveIsSignedIn">
                <div class="flex items-center">
                    {{ $t('auto_sync_drive') }}
                    <question-mark-circle-icon class="w-5 ml-1" v-tippy="`<div class=\'text-xs\'>${$t('auto_sync_explanation')}</div>`"/>
                </div>
            </switch-button>
        </div>
    </div>
</template>

<script>
import SwitchButton from '@/components/SwitchButton.vue'
import Dialog from 'primevue/dialog'
import driveMixin from '../mixins/drive.js'

export default {
    mixins: [driveMixin],

    components: { Dialog, SwitchButton },

    data () {
        return {
            test: false,
            renameModal: false,
            deleteModal: false,
            newName: '',
            errorMsg: null,
            successMsg: null,
            userEmail: null
        }
    },

    computed: {
        syncStatus () {
            if (this.driveIsError) return {text: this.$t('error'), class: 'text-red-400'}
            if (this.driveIsLoading) return {text: this.$t('loading') + '...', class: 'text-orange-400'}
            if (this.driveIsSyncing) return {text: this.$t('syncing') + '...', class: 'text-yellow-400'}
            if (this.driveIsWaiting) return {text: this.$t('waiting') + '...', class: 'text-gray-400'}

            return {text: this.$t('synced'), class: 'text-green-400'}
        },

        keepItemOpen: {
            get () { return this.settings.planner_keep_item_open },
            set (value) { this.setSetting('planner_keep_item_open', value) }
        },

        showAddInput: {
            get () { return this.settings.planner_show_add_input },
            set (value) { this.setSetting('planner_show_add_input', value) }
        },

        tabOrder: {
            get () { return this.settings.planner_tab_order },
            set (value) { this.setSetting('planner_tab_order', value) }
        },

        hourFormat: {
            get () { return this.settings.hour_format },
            set (value) { this.setSetting('hour_format', value) }
        },

        autoSync: {
            get () { return this.settings.auto_sync },
            set (value) { this.setSetting('auto_sync', value) }
        },
    },

    mounted () {
        if (this.driveIsSignedIn) {
            this.userEmail = window.gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile()?.getEmail()
        }
    },

    methods: {
        nameChanged (e) {
            this.newName = e.target.value
        },

        saveName () {
            this.setSetting('account_names', { ...this.settings.account_names, [this.account]: this.newName })
            this.renameModal = false
        },

        setAccount (e) {
            this.account = e.target.value
            localStorage.setItem('account', this.account)
        },

        addAccount () {
            let newAccount = 'account-'

            if (!this.accounts.length) newAccount += 2
            else newAccount += (Math.max(...this.accounts.map(s => Number(s.split('-')[1]))) + 1)

            this.accounts.push(newAccount)

            let newData = {}
            Object.keys(this.defaults).forEach(key => {
                let k = `${newAccount}-${key}`
                newData[k] = JSON.parse(JSON.stringify(this.defaults[key]))
            })

            this.data = Object.assign({}, this.data, newData)

            this.account = newAccount

            localStorage.setItem('accounts', JSON.stringify(this.accounts))
            localStorage.setItem('account', newAccount)
            this.updated()
        },

        deleteAccount () {
            let i = this.accounts.findIndex(a => a === this.account)
            if (i >= 0) this.accounts.splice(i, 1)

            this.account = 'main'
            localStorage.setItem('accounts', JSON.stringify(this.accounts))
            localStorage.setItem('account', 'main')
            this.updated()

            this.deleteModal = false
        },

        async login () {
            if (!window.gapi) this.toast = 'Google Drive not loaded, please refresh the page.'
            else {
                this.driveIsError = false
                await window.gapi.auth2.getAuthInstance().signIn()
                this.driveGetFiles()
                this.userEmail = window.gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile()?.getEmail()
            }
        },

        logout () {
            if (!window.gapi) this.toast = 'Google Drive not loaded, please refresh the page.'
            else {
                window.gapi.auth2.getAuthInstance().signOut()
                this.userEmail = null
            }
        },

        download () {
            let data = {
                goals: this.goals,
                inactive: this.inactive,
                notes: this.notes,
                custom_items: this.custom_items,
                customs: this.customs,
                inventory: this.inventory,
                tasks: this.tasks,
                achievements: this.achievements,
                ar: this.ar,
                wl: this.wl,
                server: this.server,
            }

            let name = this.dayjs().format('YYYY-MM-DD-HH-mm') + `-${this.account}-seelie.json`

            const a = document.createElement('a')
            a.href = URL.createObjectURL(new Blob([JSON.stringify(data)], { type: "application/JSON" }))
            a.setAttribute('download', name)
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        },

        clickUpload () {
            this.errorMsg = null
            this.successMsg = null
            this.$refs.file && this.$refs.file.click()
        },

        upload (v) {
            const files = v.target.files
            if (files.length <= 0) return false

            const fr = new FileReader()

            fr.onload = e => {
                const result = JSON.parse(e.target.result)

                if (!result.goals && !result.achievements && !result.customs && !result.tasks) {
                    this.errorMsg = this.$t('not_seelie_account_format')
                    return
                }

                this.goals = typeof result.goals == 'string' ? JSON.parse(result.goals) : (result.goals || [])
                this.inactive = typeof result.inactive == 'string' ? JSON.parse(result.inactive) : (result.inactive || {})
                this.notes = typeof result.notes == 'string' ? JSON.parse(result.notes) : (result.notes || {})
                this.custom_items = typeof result.custom_items == 'string' ? JSON.parse(result.custom_items) : (result.custom_items || [])
                this.customs = typeof result.customs == 'string' ? JSON.parse(result.customs) : (result.customs || {})
                this.inventory = typeof result.inventory == 'string' ? JSON.parse(result.inventory) : (result.inventory || [])
                this.tasks = typeof result.tasks == 'string' ? JSON.parse(result.tasks) : (result.tasks || [])
                this.achievements = typeof result.achievements == 'string' ? JSON.parse(result.achievements) : (result.achievements || {})

                this.ar = result.ar || 1
                this.wl = result.wl || 0
                this.server = result.server || 'america'

                v.target.value = ''

                this.checkOldStorage()
                this.checkWrongTraveler()
                this.checkDuplicates()
                this.checkOldTasks()

                this.successMsg = this.$t('account_data_imported')
            }

            fr.readAsText(files.item(0))
        }
    },
}
</script>../components/SwitchButton.vue/index.js
