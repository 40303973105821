import { createI18n } from 'vue-i18n'
import enGame from './locales/game/en.json'
import enSeelie from './locales/seelie/en.json'
import enCommon from './common/locales/en.json'

const available = ['en', 'pt', 'es', 'zh', 'tw', 'de', 'fr', 'id', 'it', 'ja', 'ko', 'ru', 'vi', 'th', 'tr']

function detectLocale () {
    let locale = localStorage.getItem('locale')

    if (!locale || !available.includes(locale)) {
        locale = 'en'
        if ((window.navigator.language || navigator.userLanguage || '').includes('pt')) locale = 'pt'
        if ((window.navigator.language || navigator.userLanguage || '').includes('es')) locale = 'es'
        if ((window.navigator.language || navigator.userLanguage || '').includes('zh')) locale = 'zh'
        if ((window.navigator.language || navigator.userLanguage || '').includes('tw')) locale = 'tw'
        if ((window.navigator.language || navigator.userLanguage || '').includes('de')) locale = 'de'
        if ((window.navigator.language || navigator.userLanguage || '').includes('fr')) locale = 'fr'
        if ((window.navigator.language || navigator.userLanguage || '').includes('id')) locale = 'id'
        if ((window.navigator.language || navigator.userLanguage || '').includes('it')) locale = 'it'
        if ((window.navigator.language || navigator.userLanguage || '').includes('ja')) locale = 'ja'
        if ((window.navigator.language || navigator.userLanguage || '').includes('ko')) locale = 'ko'
        if ((window.navigator.language || navigator.userLanguage || '').includes('ru')) locale = 'ru'
        if ((window.navigator.language || navigator.userLanguage || '').includes('vi')) locale = 'vi'
        if ((window.navigator.language || navigator.userLanguage || '').includes('th')) locale = 'th'
        if ((window.navigator.language || navigator.userLanguage || '').includes('tr')) locale = 'tr'
    }

    return locale
}

async function loadInitialLocale (locale) {
    const messages = { en: { ...enSeelie, ...enGame, ...enCommon } }

    if (locale != 'en') {
        const [seelie, game, common] = await Promise.all([
            import(`./locales/seelie/${locale}.json`),
            import(`./locales/game/${locale}.json`),
            import(`./common/locales/${locale}.json`)
        ])

        messages[locale] = {...seelie.default, ...common.default, ...game.default}
    }

    return messages
}

export default async function setupI18n () {
    let locale = detectLocale()
    let messages = await loadInitialLocale(locale)

    return createI18n({
        locale,
        messages,
        warnHtmlInMessage: 'off',
        silentFallbackWarn: true,
        silentTranslationWarn: !import.meta.env.DEV,
        fallbackLocale: 'en',
    })
}
