<template>
    <div class="flex flex-col items-center mt-4">
        <div class="font-semibold uppercase flex items-center">
            <div class="mr-2">{{ $t('goal_cost') }}</div>
            <button
                class="bg-green-600 rounded px-2 py-1 uppercase text-xs font-semibold tracking-wide flex items-center"
                v-tippy="`<div class=\'text-center text-xs\'>${$t('done_explanation')}</div>`"
                @click="done"
            ><check-circle-icon class="w-4 mr-1"/> {{ $t('done') }}</button>
        </div>
        <div class="mt-3 flex flex-wrap items-center justify-center gap-1">
            <template v-for="(item, index) in costs" :key="`item-${index}`">
                <template v-for="i in ((maxTier[item.type] || 0) + 1)" :key="`${item.item}-${i}`">
                    <item-cost
                        :item="item"
                        :tier="i-1"
                        v-if="typeof item.need === 'number' ? item.need : item.need[i-1]"
                        :release="items[item?.item]"
                    />
                </template>
            </template>
        </div>

        <div v-if="isInactive" class="bg-yellow-700 text-sm rounded p-2 mt-4 leading-none tracking-tight font-semibold flex items-center">
            <exclamation-circle-icon class="w-5 mr-2 flex-shrink-0"/>
            {{ isWeapon ? $t('weapon_inactive') : $t('character_inactive') }}
        </div>
    </div>
</template>

<script>
import ItemCost from './ItemCost'

export default {
    emits: ['done'],

    components: { ItemCost },

    props: {
        costs: [Array, Object],
        subject: [String, Number],
        isInactive: {
            type: Boolean,
            default: false
        },
        isWeapon: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        done () {
            Object.values(this.costs).forEach(cost => {
                if (typeof cost.need === 'object'){
                    let need = [...cost.need]

                    for (let i = 3; i >= 0; i--) {
                        if (need[i] === undefined) continue

                        let have = this.getInventory(cost.type, cost.item, i)

                        if (i > 0 && (have - need[i]) < 0) {
                            need[i-1] += (have - need[i]) * 3 * -1
                        }

                        this.setInventory(cost.type, cost.item, i, have - need[i])
                    }
                }

                if (cost.item === 'xp') { // 20k, 5k, 1k
                    let have1 = this.getInventory('xp', 'xp', 0)
                    let have2 = this.getInventory('xp', 'xp_sub_1', 0)
                    let have3 = this.getInventory('xp', 'xp_sub_0', 0)

                    let need1 = Math.floor(cost.need / 20000)
                    let need2 = Math.floor((cost.need - (need1 * 20000)) / 5000)
                    let need3 = Math.ceil((cost.need - (need1 * 20000) - (need2 * 5000)) / 1000)

                    let new1 = have1 - need1
                    let new2 = have2 - need2
                    let new3 = have3 - need3

                    if (new1 < 0) new2 += new1 * 4
                    if (new2 < 0) new3 += new2 * 5
                    if (new3 < 0) {
                        if (new2 > 0) new2 += Math.floor(new3 / 5)
                        else if (new1 > 0) new1 += Math.floor(new3 / 20)
                    }

                    this.setInventory('xp', 'xp', 0, new1)
                    this.setInventory('xp', 'xp_sub_1', 0, new2)
                    this.setInventory('xp', 'xp_sub_0', 0, new3)
                }

                else if (cost.item === 'wep_xp') { // 10k, 2k, 400
                    let have1 = this.getInventory('wep_xp', 'wep_xp', 0)
                    let have2 = this.getInventory('wep_xp', 'wep_xp_sub_1', 0)
                    let have3 = this.getInventory('wep_xp', 'wep_xp_sub_0', 0)

                    let need1 = Math.floor(cost.need / 10000)
                    let need2 = Math.floor((cost.need - (need1 * 10000)) / 2000)
                    let need3 = Math.ceil((cost.need - (need1 * 10000) - (need2 * 2000)) / 1000)

                    let new1 = have1 - need1
                    let new2 = have2 - need2
                    let new3 = have3 - need3

                    if (new1 < 0) new2 += new1 * 5
                    if (new2 < 0) new3 += new2 * 5
                    if (new3 < 0) {
                        if (new2 > 0) new2 += Math.floor(new3 / 5)
                        else if (new1 > 0) new1 += Math.floor(new3 / 25)
                    }

                    this.setInventory('wep_xp', 'wep_xp', 0, new1)
                    this.setInventory('wep_xp', 'wep_xp_sub_1', 0, new2)
                    this.setInventory('wep_xp', 'wep_xp_sub_0', 0, new3)
                }

                else if (typeof cost.need === 'number') {
                    let have = this.getInventory(cost.type, cost.item, 0)
                    this.setInventory(cost.type, cost.item, 0, have - cost.need)
                }
            })

            this.$emit('done')
        }
    }
}
</script>
