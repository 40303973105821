export default [
    {
        key: 'abyss',
        type: 'permanent',
        name: 'spiral_abyss',
        img: '/img/activity/permanent/abyss-icon.png',
        bg: '/img/activity/permanent/abyss-bg.png',
        reset: [16]
    },
    {
        key: 'theater',
        type: 'permanent',
        name: 'imaginarium_theater',
        img: '/img/activity/permanent/theater-icon.png',
        bg: '/img/activity/permanent/theater-bg.png',
        reset: [1],
    },
]
