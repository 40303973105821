<template>
    <div
        class="rounded-2xl flex items-center justify-between h-16 relative overflow-hidden glass"
        :class="[endDiffClass && endDiffClass.line || null, { 'opacity-50': event.future }]"
    >
        <template v-if="event.color">
            <img class="absolute w-full h-full object-cover scale-110 opacity-50" :src="event.bg" style="filter: blur(4px) brightness(.6) contrast(2)">
            <div class="absolute inset-0 bg-gradient-to-br to-gray-900/10" :class="event.color" style="filter: saturate(1.2)"></div>
            <!-- <div class="absolute inset-0 opacity-50 bg-black"></div> -->
        </template>

        <img v-else-if="event.bg" class="absolute w-full h-full object-cover scale-110 blur-sm opacity-80" :src="event.bg">
        <div v-else class="absolute inset-0 bg-gradient-to-br from-purple-700/10 to-purple-950/20"></div>


        <!-- <div class="absolute inset-0 glass"></div> -->

        <div class="flex items-center z-20 p-2">
            <button v-if="event.type == 'permanent' || event.future"
                class="rounded-full py-1 px-1 flex items-center justify-center"
                :class="event.future ? 'opacity-50 bg-red-500' : 'bg-gray-400 opacity-75'"
                @click="togglePermanentDone"
            >
                <component :is="event.future ? 'reply-icon' : 'check-icon'" class="w-5"/>
            </button>
            <div v-else class="">
                <button
                    class="rounded-full py-1 px-1 flex items-center justify-center"
                    :class="event.future ? 'opacity-50 bg-red-500' : 'bg-gray-400 opacity-75'"
                    v-tippy="{ html: `${event.key}-tippy`, interactive: true, placement: 'right', trigger: 'click' }"
                >
                    <component :is="event.future ? 'reply-icon' : 'check-icon'" class="w-5"/>
                </button>

                <div :id="`${event.key}-tippy`" class="tippy-seelie z-10">
                    <div class="-my-1">
                        <p class="font-semibold text-xs">{{ $t('next_occurrence') }}:</p>

                        <div class="mt-1 flex gap-1">
                            <button v-if="endDiffDays >= 1" @click="toggleTemporaryNext(1)" class="bg-gray-600 py-1 px-2 font-semibold rounded-lg text-xs">{{ resetHours }}</button>
                            <button v-if="endDiffDays >= 2" @click="toggleTemporaryNext(2)" class="bg-gray-600 py-1 px-2 font-semibold rounded-lg text-xs">1d{{ resetHours }}</button>
                            <button v-if="endDiffDays >= 3" @click="toggleTemporaryNext(3)" class="bg-gray-600 py-1 px-2 font-semibold rounded-lg text-xs">2d{{ resetHours }}</button>
                            <button @click="toggleTemporaryNext('done')" class="bg-gray-600 py-1 px-2 font-semibold rounded-lg text-xs">{{ $t('complete') }}</button>
                        </div>
                    </div>
                </div>
            </div>

            <img :src="event.img" class="ml-3 h-12 w-12 object-cover shadow" :class="{ 'rounded-full': !event.notRounded }">

            <p class="ml-3 font-semibold text-sm sm:text-base md:text-lg sm:flex items-center flex-wrap">
                <span v-if="Object.keys(typeTags).includes(event.type)"
                    class="text-xs bg-purple-700/60 rounded-lg px-2 py-1 sm:mr-2 text-nowrap font-semibold"
                >{{ typeTags[event.type] }}</span>

                {{ event[`name_${locale}`] || $t(event.name) }}
            </p>
        </div>

        <div class="flex flex-col items-end ml-1 p-2">
            <div
                class="rounded py-1 px-2 text-xs lowercase font-semibold flex items-center z-10 shrink-0 text-nowrap"
                :class="endDiffClass?.remaining || 'bg-gray-500'"
            >
                <calendar-icon class="w-4 h-4 mr-1"/>
                <span class="hidden md:inline mr-1">{{ $t('remaining_time') }}:</span> {{ endDiffText }}
            </div>

            <div v-if="event.task?.done || (event.type == 'permanent' && event.future)" class="mt-0.5 rounded py-0.5 px-1 text-[.6rem] lowercase font-semibold flex items-center z-10 shrink-0 bg-teal-700">
                <check-icon class="w-3 h-3 mr-1"/>
                <span class="hidden md:inline mr-1">{{ $t('complete') }}</span>
            </div>

            <div v-else-if="nextDiffHours > 0" class="mt-0.5 rounded py-0.5 px-1 text-[.6rem] lowercase font-semibold flex items-center z-10 shrink-0 bg-purple-700">
                <refresh-icon class="w-3 h-3 mr-1"/>
                <span class="hidden md:inline mr-1">{{ $t('next') }}:</span> {{ nextDiffText }}
            </div>
        </div>
    </div>
</template>

<script>
import { ReplyIcon } from '@heroicons/vue/solid'

export default {
    components: { ReplyIcon },

    props: {
        event: Object
    },

    data () {
        return {
            typeTags: {
                'checkin': 'Check-In',
                'tcg': 'TCG'
            }
        }
    },

    computed: {
        locale () {
            return this.$i18n.locale == 'pt' ? 'pt' : 'en'
        },

        end () {
            if (this.event.end) {
                return this.event.end
            }

            if (this.event.start) {
                let start = this.dayjs.utc(`${this.event.start} 04:00`)
                let pastCycles = Math.floor(this.serverTime.diff(start, 'week') / this.event.rec) + 1

                return start.add(pastCycles * this.event.rec, 'week').format('YYYY-MM-DD 03:59')
            }

            const reset = this.event.reset
            const today = this.resetDay
            let end

            if (typeof reset == 'string') {
                const dayOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'].indexOf(reset)

                end = today.weekday() < dayOfWeek ? today.weekday(dayOfWeek) : today.add(1, 'week').weekday(dayOfWeek)
            } else {
                end = reset
                    .map(day => today.date() < day ? today.date(day) : today.add(1, 'month').date(day))
                    .sort((a, b) => a - b)[0]
            }

            return end.format('YYYY-MM-DD 03:59')
        },

        endDiffHours () {
            return (this.event.globalEnd ? this.time : this.serverTime)
                .diff(this.dayjs.utc(this.end), 'hour') * -1
        },

        endDiffDays() {
            return (this.event.globalEnd ? this.time : this.serverTime)
                .diff(this.dayjs.utc(this.end), 'days') * -1
        },

        endDiffText () {
            if (this.endDiffHours < 24) return `${this.endDiffHours}h`

            let days = Math.floor(this.endDiffHours / 24)
            let hours = this.endDiffHours % 24

            if (hours < 1) return `${days}d`

            return `${days}d ${hours}h`
        },

        endDiffClass () {
            if (this.endDiffHours <= 24) return { remaining: '!bg-red-600', line: '!bg-red-600/30 !border !border-red-600/20' }
            if (this.endDiffHours <= 48) return { remaining: '!bg-orange-500', line: '!bg-orange-500/30 !border !border-orange-500/20' }

            return null
        },

        nextDiffHours () {
            if (this.event.type == 'permanent' || !this.event.task?.next) return null

            let next = this.event.task.next

            if (next.length == 10) next = `${next} 04:00`

            return this.serverTime.diff(this.dayjs.utc(next), 'hour') * -1
        },

        nextDiffText () {
            if (this.nextDiffHours === null) return null

            const totalHours = this.nextDiffHours

            if (totalHours < 24) return `${totalHours}h`

            const days = Math.floor(totalHours / 24)
            const hours = totalHours % 24

            if (hours < 1) return `${days}d`

            return `${days}d ${hours}h`
        },

        resetHours () {
            let hours = this.serverTime.diff(this.dayjs.utc(`${this.resetDay.add(1, 'day').format('YYYY-MM-DD')} 04:00`), 'hour') * -1

            return `${hours}h`
        }
    },

    methods: {
        toggleTemporaryNext (days) {
            let tasks = this.tasks

            let index = this.tasks.findIndex(task => task.id == this.event.key)

            let next = days == 'done' ? null : this.resetDay.add(days, 'days').format('YYYY-MM-DD 04:00')

            if (index < 0) {
                tasks.push({
                    id: this.event.key,
                    next: next,
                    done: days == 'done'
                })
            } else {
                tasks[index].next = next
                tasks[index].done = days == 'done'
            }

            this.tasks = tasks
        },

        togglePermanentDone () {
            let tasks = this.tasks

            let index = this.tasks.findIndex(task => task.id == this.event.key)

            if (this.event.future) {
                tasks.splice(index, 1)
                this.tasks = tasks
                return
            }

            if (index < 0) {
                tasks.push({
                    id: this.event.key,
                    next: this.end
                })
            } else {
                tasks[index].next = this.end
            }

            this.tasks = tasks
        }
    }
}
</script>
