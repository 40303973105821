import user from '../userData'
import costs from '../database/costs'

export default {
    data () {
        return {
            ...user
        }
    },

    computed: {
        goals: {
            get () { return this.data[`${this.account}-goals`] || [] },
            set (v) {
                this.data[`${this.account}-goals`] = v
                localStorage.setItem(`${this.account}-goals`, JSON.stringify(v))
                this.updated()
            },
        },

        inactive: {
            get () { return this.data[`${this.account}-inactive`] || {} },
            set (v) {
                this.data[`${this.account}-inactive`] = v
                localStorage.setItem(`${this.account}-inactive`, JSON.stringify(v))
                this.updated()
            },
        },

        notes: {
            get () { return this.data[`${this.account}-notes`] || {} },
            set (v) {
                this.data[`${this.account}-notes`] = v
                localStorage.setItem(`${this.account}-notes`, JSON.stringify(v))
                this.updated()
            },
        },

        custom_items: {
            get () { return this.data[`${this.account}-custom_items`] || [] },
            set (v) {
                this.data[`${this.account}-custom_items`] = v
                localStorage.setItem(`${this.account}-custom_items`, JSON.stringify(v))
                this.updated()
            },
        },

        customs: {
            get () { return this.data[`${this.account}-customs`] || {} },
            set (v) {
                this.data[`${this.account}-customs`] = v
                localStorage.setItem(`${this.account}-customs`, JSON.stringify(v))
                this.updated()
            },
        },

        inventory: {
            get () { return this.data[`${this.account}-inventory`] || [] },
            set (v) {
                this.data[`${this.account}-inventory`] = v
                localStorage.setItem(`${this.account}-inventory`, JSON.stringify(v))
                this.updated()
            },
        },

        tasks: {
            get () { return this.data[`${this.account}-tasks`] || [] },
            set (v) {
                this.data[`${this.account}-tasks`] = v
                localStorage.setItem(`${this.account}-tasks`, JSON.stringify(v))
                this.updated()
            },
        },

        achievements: {
            get () { return this.data[`${this.account}-achievements`] || {} },
            set (v) {
                this.data[`${this.account}-achievements`] = v
                localStorage.setItem(`${this.account}-achievements`, JSON.stringify(v))
                this.updated()
            },
        },

        ar: {
            get () { return this.data[`${this.account}-ar`] || 1 },
            set (v) {
                v = Number(v)
                if (v < 1) v = 1
                if (v > 60) v = 60
                this.data[`${this.account}-ar`] = v
                localStorage.setItem(`${this.account}-ar`, JSON.stringify(v))
                this.updated()
            },
        },

        wl: {
            get () {
                let wl = Number(this.data[`${this.account}-wl`]) || 0
                return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(wl) ? wl : 0
            },
            set (v) {
                v = Number(v)
                if (v < 0) v = 0
                if (v > 9) v = 9
                this.data[`${this.account}-wl`] = v
                localStorage.setItem(`${this.account}-wl`, JSON.stringify(v))
                this.updated()
            },
        },

        server: {
            get () { return this.data[`${this.account}-server`] || 'america' },
            set (v) {
                this.data[`${this.account}-server`] = v
                localStorage.setItem(`${this.account}-server`, JSON.stringify(v))
                this.updated()
            },
        },

        gender: {
            get () { return this.data[`${this.account}-gender`] || 'male' },
            set (v) {
                this.data[`${this.account}-gender`] = v
                localStorage.setItem(`${this.account}-gender`, JSON.stringify(v))
                this.updated()
            },
        },

        accountResin: {
            get () { return this.data[`${this.account}-resin`] },
            set (v) {
                this.data[`${this.account}-resin`] = v
                localStorage.setItem(`${this.account}-resin`, JSON.stringify(v))
                this.updated()
            },
        },

        resinAmount () {
            return this.accountResin?.amount || 0
        },

        resinTime () {
            return this.accountResin?.time
        },

        computedResin () {
            if (!this.resinTime) return 0

            let diff = this.time.diff(this.resinTime, 'seconds')
            let resinSince = Math.floor(diff / this.resinInterval * 100) / 100

            let sum = this.resinAmount + resinSince

            if (resinSince <= 0 || this.resinAmount >= this.resinCap) return this.resinAmount

            if (sum >= this.resinCap) return this.resinCap

            return Math.floor(sum*100)/100
        },

        totalCosts () {
            let calculated = []

            this.goals.forEach(goal => {
                let costs
                if (goal.type === 'character') costs = this.characterCosts(goal)
                else if (goal.type === 'talent') costs = this.talentCosts(goal)
                else if (goal.type === 'weapon') costs = this.weaponCosts(goal)
                else return

                if (this.inactive[goal.character || goal.id]) return

                Object.values(costs).forEach(cost => {
                    if (!cost.need || (typeof cost.need === 'object' && !cost.need.reduce((a, b) => a + b, 0))) return

                    if (!cost.item) return

                    let index = calculated.findIndex(c => c.item === cost.item)

                    if (index >= 0) {
                        if (typeof cost.need === 'object')
                            calculated[index].need = calculated[index].need.map((a, i) => a + cost.need[i])
                        else
                            calculated[index].need += cost.need

                        if (!calculated[index].characters.find(c => c === goal.character)) {
                            calculated[index].characters.push(goal.character)
                        }

                        if (!goal.character && goal.type === 'weapon') {
                            calculated[index].weapons.push({ key: goal.weapon, id: goal.id })
                        }
                    }
                    else {
                        let data = {
                            item: cost.item,
                            type: cost.type,
                            need: cost.need,
                            characters: [ goal.character ],
                            weapons: [],
                            custom: false
                        }

                        if (!goal.character && goal.type === 'weapon') {
                            data.weapons.push({ key: goal.weapon, id: goal.id })
                        }

                        calculated.push(data)
                    }
                })
            })

            let xpIndex = calculated.findIndex(c => c.item === 'xp')
            if (xpIndex >= 0) calculated[xpIndex].need /= 20000

            let wepXpIndex = calculated.findIndex(c => c.item === 'wep_xp')
            if (wepXpIndex >= 0) calculated[wepXpIndex].need /= 10000

            !this.inactive.custom && this.custom_items.forEach(item => {
                if (!item.value) return

                let index = calculated.findIndex(c => c.item === item.item)

                if (index >= 0) {
                    if (typeof calculated[index].need === 'object')
                        calculated[index].need[item.tier] += item.value
                    else
                        calculated[index].need += item.value

                    calculated[index].custom = true
                }
                else {
                    let max = this.maxTier[item.type]
                    let need = 0

                    if (!max) need = item.value
                    else {
                        need = new Array(max + 1).fill(0)
                        need[item.tier] = item.value
                    }

                    let data = {
                        item: item.item,
                        type: item.type,
                        need,
                        characters: [],
                        weapons: [],
                        custom: true
                    }

                    calculated.push(data)
                }
            })

            return calculated
        },

        solventNeeded () {
            let total = 0

            Object.values(this.bosses).forEach(items => {
                if (typeof items == 'string') return

                let missing = 0
                let extra = 0

                items.forEach(item => {
                    let need = this.getNeed('boss', item, 0)
                    let have = this.getInventory('boss', item, 0)

                    if (have > need) extra += have - need
                    if (need > have) missing += need - have
                })

                total += Math.min(missing, extra)
            })

            return total
        },

        solventMissing () {
            return this.getInventory('special', 'dream_solvent', 0) < this.solventNeeded
        }
    },

    methods: {
        updated (value) {
            this.lastUpdate = value ? this.dayjs(value) : this.dayjs()
            localStorage.setItem('last_update', this.lastUpdate.toISOString())

            this.driveIsWaiting = Number(new Date())
        },

        setSetting (key, value) {
            this.settings[key] = value
            localStorage.setItem('settings', JSON.stringify(this.settings))
            this.updated()
        },

        // costClass (item, tier) {
        //     let need = typeof item.need === 'object' ? item.need[tier] : item.need

        //     if (item.item === 'xp') need /= 20000
        //     else if (item.item === 'wep_xp') need /= 10000

        //     let have = this.getInventory(item.type, item.item, tier)

        //     if (item.item === 'xp')
        //         have = have + Math.floor(this.getInventory('xp', 'xp_sub_1', 0) / 4)
        //             + Math.floor(this.getInventory('xp', 'xp_sub_0', 0) / 20)

        //     if (item.item === 'wep_xp')
        //         have = have + Math.floor(this.getInventory('wep_xp', 'wep_xp_sub_1', 0) / 5)
        //             + Math.floor(this.getInventory('wep_xp', 'wep_xp_sub_0', 0) / 25)

        //     let craft = this.getCraft(item.type, item.item, tier)

        //     if (have >= need) return 'bg-green-500'
        //     if (have + craft >= need) return 'bg-yellow-500'
        //     return 'bg-red-500'
        // },

        addGoal (data) {
            let index
            let goals = this.goals

            if (data.character) index = goals.findIndex(g => g.character === data.character && g.type === data.type)
            else if (data.id) index = goals.findIndex(g => g.id === data.id)

            if (index >= 0) {
                goals[index] = { ...this.goals[index], ...data }
            }
            else {
                let lastId = this.goals
                    ?.map(g => g.id)
                    ?.filter(id => typeof id == 'number')
                    ?.sort((a, b) => a < b ? 1 : -1)[0]

                data.id = (lastId || 0) + 1
                goals.push(data)

                console.log(data)
            }

            this.goals = goals

            return data
        },

        removeGoal (goal) {
            let goals = this.goals

            let index = goals.findIndex(o => o.id === goal.id)
            if (index === -1) return

            this.setInactive(goal.id, false)
            goals.splice(index, 1)

            this.goals = goals
        },

        removeGoals (character) {
            let goals = this.goals

            goals = goals.filter(g => g.character !== character)
            console.log('deleted ' + character)

            this.setInactive(character, false)
            this.goals = goals
        },

        setInactive (subject, value) {
            let inactive = this.inactive

            if (value) inactive[subject] = value
            else { delete inactive[subject] }

            this.inactive = inactive
        },

        setNotes (subject, value) {
            let notes = this.notes

            if (value) notes[subject] = value
            else { delete notes[subject] }

            this.notes = notes
        },

        setCustom (id, data) {
            let customs = this.customs

            if (data) customs[id] = data
            else {
                this.removeGoals(id)
                delete customs[id]
            }

            this.customs = customs
        },

        getCustomItem (type, item, tier) {
            let found = this.custom_items.find(i => i.type === type && i.item === item && i.tier === tier)
            return (found && found.value) || null
        },

        setCustomItem (type, item, tier, value) {
            let custom = this.custom_items

            value = Number(value)
            if (value < 0) value = 0
            let data = { type: type, item: item, tier, value }

            let index = custom.findIndex(i => i.type === type && i.item === item && i.tier === tier)

            if (index >= 0) custom[index] = data
            else custom.push(data)

            this.custom_items = custom
        },

        getInventory (type, item, tier) {
            let found = this.inventory.find(i => i.type === type && i.item === item && i.tier === tier)
            return (found && found.value) || null
        },

        setInventory (type, item, tier, value) {
            if (!item || !type) return

            let inventory = this.inventory

            value = Number(value)
            if (value < 0) value = 0
            let data = { type: type, item: item, tier, value }

            let index = inventory.findIndex(i => i.type === type && i.item === item && i.tier === tier)

            if (index >= 0) inventory[index] = data
            else inventory.push(data)

            this.inventory = inventory
        },

        getCraft (type, item, tier) {
            let index = this.totalCosts.findIndex(tc => tc.item === item)
            if (!this.totalCosts[index] || !this.maxTier[type] || tier === 0) return 0

            let items = new Array(this.maxTier[type] + 1).fill(0).map((_, i) => ({
                need: this.totalCosts[index].need[i],
                have: this.getInventory(type, item, i),
                craft: 0
            }))

            for (let i = 0; i < items.length; i++) {
                const current = items[i]
                const next = items[i+1]
                if (!next) continue

                current.totalHave = current.totalHave || current.have
                next.canCraft = Math.max(0, Math.floor((current.totalHave - current.need) / 3))
                next.totalHave = next.have + next.canCraft
                next.craft = Math.max(0, Math.min(next.need - next.have, next.canCraft))
            }

            for (let i = items.length - 1; i > 0; i--) {
                const current = items[i]
                const previous = items[i-1]
                if (!previous) return

                const previousLeftover = Math.max(0, previous.have - previous.need)
                const currentMissingFromPrevious = current.craft*3 - previousLeftover

                previous.craft += Math.max(0, currentMissingFromPrevious)
            }

            return items[tier]?.craft || 0

            // let currentTierNeed = this.totalCosts[index].need[tier]
            // let currentTierHave = this.getInventory(type, item, tier)

            // let previousTierNeed = this.totalCosts[index].need[tier - 1]
            // let previousTierHave = this.getInventory(type, item, tier - 1)

            // if (tier > 1) previousTierHave += this.getCraft(type, item, tier - 1, true)

            // if (previousTierNeed >= previousTierHave) return 0
            // let craft = Math.floor((previousTierHave - previousTierNeed) / 3)

            // if (skip) return craft

            // currentTierNeed += this.getTotalNextMissing(type, item, tier)

            // let missing = currentTierNeed - currentTierHave

            // if (craft > missing) return Math.max(0, missing)

            // return craft
        },

        getTotalNextMissing (type, item, tier) {
            let index = this.totalCosts.findIndex(tc => tc.item === item)
            if (!this.totalCosts[index] || tier >= this.maxTier[type]) return 0

            let need = this.totalCosts[index].need[tier + 1]
            let have = this.getInventory(type, item, tier + 1)

            return (Math.max(0, need - have) + this.getTotalNextMissing(type, item, tier + 1)) * 3
        },

        getTotalBossNeed (item) {
            let boss = this.bosses[item]
            let items = this.bosses[boss]

            return items.reduce((a, b) => a + this.getNeed('boss', b), 0)
        },

        getTotalBossHave (item) {
            let boss = this.bosses[item]
            let items = this.bosses[boss]

            return items.reduce((a, b) => a + this.getInventory('boss', b, 0), 0)
        },

        getBossExtra (item) {
            let boss = this.bosses[item]
            let items = this.bosses[boss]

            let need = this.getNeed('boss', item)
            let have = this.getInventory('boss', item, 0)

            let anyMissing = items.filter(i => this.getNeed('boss', i) > this.getInventory('boss', i, 0)).length

            return anyMissing && have > need

            // if (this.getTotalBossHave(item) >= this.getTotalBossNeed(item)) return 1

            // let itemsNeed = items.reduce((a, b) => a + this.getNeed('boss', b), 0)
            // if (!itemsNeed) return false

            // if (need > have) return -1

            // let itemsHave = items.reduce((a, b) => a + this.getInventory('boss', b, 0), 0)
            // if (itemsHave < itemsNeed) return -1

            // let solventHave = this.getInventory('special', 'dream_solvent', 0)
            // let solventNeed = this.solventNeeded

            // if (solventHave < solventNeed) return -1
        },

        getTotal (type, item, tier) {
            if (type == 'boss' && !this.solventMissing && this.getTotalBossHave(item) >= this.getTotalBossNeed(item)) return this.getNeed('boss', item)

            let have = this.getInventory(type, item, tier)

            if (item === 'xp')
                return have + Math.floor(this.getInventory('xp', 'xp_sub_1', 0) / 4)
                    + Math.floor(this.getInventory('xp', 'xp_sub_0', 0) / 20)

            if (item === 'wep_xp')
                return have + Math.floor(this.getInventory('wep_xp', 'wep_xp_sub_1', 0) / 5)
                    + Math.floor(this.getInventory('wep_xp', 'wep_xp_sub_0', 0) / 25)

            return have + this.getCraft(type, item, tier)
        },

        getNeed (type, item, tier) {
            if (item == 'dream_solvent') return this.solventNeeded

            let index = this.totalCosts.findIndex(tc => tc.item === item)

            if (!this.totalCosts[index]) return 0

            if (typeof this.totalCosts[index].need === 'number')
                return this.totalCosts[index].need

            return this.totalCosts[index].need[tier]
        },

        getMissing (type, item, tier) {
            return Math.ceil(Math.max(0, (this.getTotal(type, item, tier) - this.getNeed(type, item, tier)) * -1))
        },

        characterCosts (goal) {
            let char = this.characters[goal.character] || this.customs[goal.character]

            if (!char) return {}

            let calculated = {
                xp: { type: 'xp', item: 'xp', need: 0 },
                mora: { type: 'mora', item: 'mora', need: 0 },
                common: { type: 'common', item: char.common, need: [0, 0, 0] },
                element_1: { type: 'element_1', item: char.element_1, need: [0, 0, 0, 0] },
                element_2: { type: 'element_2', item: char.element_2, need: 0 },
                local: { type: 'local', item: char.local, need: 0 },
            }

            costs.character.forEach(cost => {
                if (goal.current.level < cost.level && goal.goal.level >= cost.level) {
                    calculated.xp.need += cost.xp
                    calculated.mora.need += (cost.xp / 5)
                }

                if (goal.current.asc < cost.asc && goal.goal.asc >= cost.asc) {
                    calculated.common.need[cost.common[1]] += cost.common[0]
                    calculated.element_1.need[cost.element_1[1]] += cost.element_1[0]
                    if (!char.traveler) calculated.element_2.need += cost.element_2
                    calculated.local.need += cost.local
                    calculated.mora.need += cost.mora
                }
            })

            return calculated
        },

        talentCosts (goal) {
            let calculated = {
                mora: { type: 'mora', item: 'mora', need: 0 },
                crown: { type: 'special', item: 'crown', need: 0 },
            }

            let common, talent, boss, traveler

            let skills = ['normal', 'skill', 'burst']

            let characters = { ...this.characters, ...this.customs }

            let character = characters[goal.character]

            skills.forEach(skill => {
                costs.talent.forEach(cost => {
                    if (cost.level <= 1 || goal[skill].current >= cost.level || goal[skill].goal < cost.level) return

                    if (character.traveler) {
                        if (character.element == 'geo' && skill == 'normal') traveler = 'anemo'
                        else traveler = character.element

                        common = this.traveler[traveler][cost.level].common
                        talent = this.traveler[traveler][cost.level].talent
                        boss = this.traveler[traveler][cost.level].boss
                    } else {
                        common = characters[goal.character].common
                        talent = characters[goal.character].talent
                        boss = characters[goal.character].boss
                    }

                    if (!calculated[common || 'common']) calculated[common || 'common'] = { type: 'common', item: common, need: [0, 0, 0] }
                    if (!calculated[talent || 'talent']) calculated[talent || 'talent'] = { type: 'talent', item: talent, need: [0, 0, 0, 0] }
                    if (!calculated[boss || 'boss']) calculated[boss || 'boss'] = { type: 'boss', item: boss, need: 0 }

                    calculated.mora.need += cost.mora
                    calculated.crown.need += cost.crown

                    calculated[boss || 'boss'].need += cost.boss
                    calculated[common || 'common'].need[cost.common[1]] += cost.common[0]
                    calculated[talent || 'talent'].need[cost.talent[1]] += cost.talent[0]
                })
            })

            return calculated
        },

        weaponCosts (goal) {
            let weapon = this.weapons[goal.weapon] || this.customs[goal.weapon]

            let calculated = {
                wep_xp: { type: 'wep_xp', item: 'wep_xp', need: 0 },
                mora: { type: 'mora', item: 'mora', need: 0 },
                wam: { type: 'wam', item: weapon.wam, need: [0, 0, 0, 0] },
                common: { type: 'common', item: weapon.common, need: [0, 0, 0] },
                common_rare: { type: 'common_rare', item: weapon.common_rare, need: [0, 0, 0] },
            }

            costs[weapon.tier + '_weapon'].forEach(cost => {
                if (goal.current.level < cost.level && goal.goal.level >= cost.level) {
                    calculated.wep_xp.need += cost.xp
                    calculated.mora.need += (cost.xp / 10)
                }

                if (goal.current.asc < cost.asc && goal.goal.asc >= cost.asc) {
                    calculated.mora.need += cost.mora
                    calculated.wam.need[cost.wam[1]] += cost.wam[0]
                    calculated.common.need[cost.common[1]] += cost.common[0]
                    calculated.common_rare.need[cost.common_rare[1]] += cost.common_rare[0]
                }
            })

            return calculated
        },

        getEstimatedRuns (item) {
            let runs
            let missing = []
            let unobtainable = false

            if (!item.need) return

            if (typeof item.need === 'number') missing = this.getMissing(item.type, item.item, 0)
            else item.need.forEach((need, tier) => missing.push(this.getMissing(item.type, item.item, tier)))

            var arKey = -1
            for (let key in this.drops.ar) {
                if (arKey < 0 || key <= this.ar) arKey = Math.max(arKey, key)
            }

            let drops = {
                ...this.drops.wl[this.wl],
                ...this.drops.ar[arKey]
            }[item.type]

            if (drops === 0) {
                unobtainable = true
                runs = 0
            }

            else if (!drops) runs = 0

            else if (item.type === 'xp') runs = missing * 20000 / drops

            else if (typeof missing === 'number') runs = missing / drops

            else runs = missing.reduce((a, b, i) => {
                if (!b) return a

                if (!drops[i]) {
                    unobtainable = true
                    return a
                }

                return a + (b * Math.pow(3, i)) / drops[i]
            }, 0)

            return { runs: Math.ceil(runs), unobtainable }
        },

        checkOldStorage () {
            let ls = localStorage

            ls.removeItem('lang')
            ls.removeItem('unsaved')

            let keys = ['characters_mode', 'inventory_mode', 'inventory_buttons']

            keys.forEach(key => {
                if (ls.getItem(key)) {
                    this.setSetting(key, ls.getItem(key))
                    ls.removeItem(key)
                }
            })

            let accountKeys = ['goals', 'inactive', 'inventory', 'tasks', 'wl']

            accountKeys.forEach(key => {
                if (ls.getItem(key)) {
                    this[key] = JSON.parse(ls.getItem(key))
                    ls.removeItem(key)
                }
            })

            if (ls.getItem('server')) {
                this.server = ls.getItem('server')
                ls.removeItem('server')
            }
        },

        checkDuplicates () {
            let goals = this.goals

            let found = false

            goals.forEach((goal, index) => {
                let i = goals.findIndex((g, i) => g.character && g.character === goal.character && g.type === goal.type && i !== index)
                if (i >= 0) {
                    goals.splice(i, 1)
                    this.goals = goals
                    found = true
                    console.log('deleting duplicate')
                }
            })
            if (found) this.checkDuplicates()
        },

        checkWrongTraveler () {
            let goals = this.goals

            let found = false
            let i = goals.findIndex(g => g.character == 'traveler')

            if (i >= 0) {
                goals.splice(i, 1)
                this.goals = goals
                found = true
                console.log('deleting wrong traveler')
            }
            if (found) this.checkWrongTraveler()
        },

        checkOldTasks () {
            if (!this.tasks.filter(t => !t.id).length) return
            console.log('needs to convert tasks...')

            let lastId = this.tasks
                ?.map(t => t.id)
                ?.filter(id => typeof id == 'number')
                ?.sort((a, b) => a < b ? 1 : -1)[0] || 0

            this.tasks = this.tasks.map(task => ({...task, id: task.id || ++lastId }))
        },
    }
}
