<template>
    <div class="mt-6">
        <div v-if="current.length">
            <p class="ml-1 uppercase font-semibold flex items-center">
                {{ $t('current_events') }}

                <svg v-if="isLoading" class="animate-spin ml-2 h-4 w-4 my-px text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            </p>

            <div class="mt-2 grid sm:grid-cols-2 gap-2 items-start" v-if="current.length">
                <event-line v-for="(event, i) in current" :event="event" :game="game" :key="`current-${i}`"/>
            </div>
        </div>

        <div v-if="upcoming.length" :class="current.length && 'mt-6'">
            <p class="ml-1 uppercase font-semibold">{{ $t('upcoming_events') }}</p>

            <div class="mt-2 grid sm:grid-cols-2 gap-2 items-start">
                <event-line v-for="(event, i) in upcoming" :event="event" :game="game" :key="`upcoming-${i}`"/>
            </div>
        </div>
    </div>
</template>

<script>
import EventLine from './EventLine.vue'
import { useEvents } from '../composables/useEvents.js'

export default {
    components: { EventLine },

    props: {
        game: String
    },

    setup (props) {
        return useEvents({
            gi: 'events',
            hsr: 'hsr_events',
            zzz: 'zzz_events'
        }[props.game])
    },

    computed: {
        events () {
            return this.computedEvents(this.time, this.serverTime)
        },

        upcoming () {
            return this.events.value.filter(e => !e.started)
        },

        current () {
            return this.events.value.filter(e => e.started)
        }
    },

    beforeCreate () {
        if (!this.isLoading) this.update()
    }
}
</script>
