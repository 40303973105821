<template>
    <div>
        <button
            class="flex items-center justify-center bg-gray-900 hover:bg-gray-950 px-4 py-1 rounded-full"
            v-tippy.hover="{ html: 'languageSelector', interactive: true }"
        >
            <img :src="`${url}img/flags/${$i18n.locale}.svg`" class="w-6 mr-2 rounded-md"/> {{ locales[$i18n.locale] }}
        </button>
    </div>

    <div id="languageSelector" class="tippy-seelie">
        <div class="grid grid-cols-2 gap-1">
            <button v-for="key in filteredLocales" :key="`change-${key}`"
                class="flex items-center justify-start bg-gray-800/50 hover:bg-gray-800 px-3 py-2 rounded-full"
                @click="changeLocale(key)"
            >
                <img :src="`${url}img/flags/${key}.svg`" class="w-6 mr-2 rounded-md"/> {{ locales[key] }}
            </button>
        </div>

        <a
            class="bg-gray-800 py-2 rounded-full w-full mt-2 block text-center"
            target="_blank"
            href="mailto:translate@seelie.me?subject=I%20want%20to%20help%20translate%20Seelie%20to%3A%20%5Blanguage%5D&body=Language%3A%20%5Binsert%20the%20language%20here%5D"
        >
            Help translate Seelie!
        </a>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                locales: {
                    zh: '中文(简体)',
                    tw: '中文(繁體)',
                    en: 'English',
                    pt: 'Português',
                    es: 'Español',
                    de: 'Deutsch',
                    fr: 'Français',
                    id: 'Indonesia',
                    it: 'Italiano',
                    ja: '日本語',
                    ko: '한국어',
                    ru: 'Русский',
                    th: 'ภาษาไทย',
                    tr: 'Türkçe',
                    vi: 'Tiếng Việt',
                }
            }
        },

        computed: {
            filteredLocales () {
                return Object.keys(this.locales).filter(l => l !== this.$i18n.locale)
            }
        },

        methods: {
            async loadLocale (locale) {
                const [ seelie, game, common ] = await Promise.all([
                    await import(`../locales/seelie/${locale}.json`),
                    await import(`../locales/game/${locale}.json`),
                    await import(`../common/locales/${locale}.json`)
                ])

                this.$i18n.setLocaleMessage(locale, {...seelie.default, ...game.default, ...common.default})
            },

            async changeLocale (locale) {
                await this.loadLocale(locale)

                this.$i18n.locale = locale
                localStorage.setItem('locale', locale)
            },
        }
    }
</script>
