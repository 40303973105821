<template>
    <div class="flex items-center w-full group">
        <button class="text-gray-300 cursor-move flex-shrink-0 transition-all opacity-0 sm:group-hover:opacity-75 task-hover-item">
            <selector-icon class="w-5"/>
        </button>

        <div class="glass rounded-2xl shadow p-2 flex relative w-full ml-2" :class="{ 'opacity-50' : diff < 0 }">
            <div class="flex items-center w-full">
                <button
                    class="mr-3 rounded-full py-1 px-1 flex items-center justify-center"
                    :class="diff < 0 ? 'opacity-50 bg-red-500' : 'bg-gray-400 opacity-75'"
                    @click="toggleDone"
                >
                    <component :is="diff < 0 ? 'reply-icon' : 'check-icon'" class="w-5"/>
                </button>
                <div class="mr-4 leading-tight flex flex-col justify-center items-start">
                    <div class="font-semibold text-sm sm:text-base" v-html="taskHtml"></div>
                    <!-- <div class="text-xs sm:text-sm text-gray-300 opacity-75">{{ task.notes }}</div> -->
                    <notes
                        v-if="task.notes?.length"
                        :note="task.notes"
                        class="bg-teal-700/60 px-2 py-1 text-sm rounded"
                        button-class="bg-teal-900/90"
                    />
                </div>
            </div>

            <button class="text-gray-300 opacity-0 transition-all group-hover:opacity-75 task-hover-item" @click="$emit('edit', task.id)" :class="{'opacity-100 sm:opacity-0': showAll}">
                <pencil-alt-icon class="w-5"/>
            </button>

            <button class="ml-2 text-red-300 opacity-75 transition-all" @click="remove" v-if="showAll">
                <trash-icon class="w-5"/>
            </button>

            <div class="flex justify-end items-center w-auto ml-2 flex-shrink-0">
                <div
                    class="rounded py-1 px-2 text-xs lowercase font-semibold flex items-center"
                    :class="classes[diffText] || 'bg-gray-500'"
                    v-tippy.arrow.left="`<span class='lowercase text-xs'>${$t('every')} ${task.recurring > 1 ? task.recurring : ''} ${$tc('day', task.recurring)}</span>`"
                >
                    <refresh-icon class="w-4 h-4 mr-1"/>
                    {{ $t(diffText) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ReplyIcon, SelectorIcon } from '@heroicons/vue/solid'
import Notes from './Notes'

export default {
    components: { ReplyIcon, SelectorIcon, Notes },

    emits: ['edit'],

    props: {
        task: Object,
        showAll: Boolean
    },

    data () {
        return {
            days: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
            classes: {
                'overdue': 'bg-red-500',
                'today': 'bg-teal-500',
                'tomorrow': 'bg-purple-700'
            }
        }
    },

    computed: {
        taskHtml () {
            return this.task.task
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#39;')
            .replace(/\[/g, '&#91;')
            .replace(/\]/g, '&#93;')
            .replace(/&#91;(.*?)&#93;/g, (_, p1) => {
                return `<span class="text-xs bg-purple-700/60 rounded-lg px-2 py-1 text-nowrap font-semibold">${p1}</span>`;
            })
        },

        diff () {
            return this.resetDay.diff(this.task.next, 'days')
        },

        diffText () {
            if (this.diff > 0) return 'overdue'
            if (this.diff === 0) return 'today'
            if (this.diff === -1) return 'tomorrow'
            return this.days[this.dayjs(this.task.next).day()]
        },
    },

    methods: {
        remove () {
            let index = this.tasks.findIndex(task => task.id == this.task.id)
            if (index < 0) return

            let tasks = this.tasks
            tasks.splice(index, 1)
            this.tasks = tasks
        },

        toggleDone () {
            let index = this.tasks.findIndex(task => task.id == this.task.id)
            if (index < 0) return

            let tasks = this.tasks

            if (this.diff < 0) {
                tasks[index].next = this.dayjs(tasks[index].next).subtract(tasks[index].recurring, 'days').format('YYYY-MM-DD')
            } else {
                tasks[index].next = this.dayjs(tasks[index].next).add(tasks[index].recurring, 'days').format('YYYY-MM-DD')
            }
            this.tasks = tasks
        }
    }
}
</script>
