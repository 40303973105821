export default {
    horn: { type: 'common_rare', other_id: ['i_61', 'i_62', 'i_63'], dim_hash: ['1874818508', '98885076', '258445116'] },
    ley_line: { type: 'common_rare', other_id: ['i_71', 'i_72', 'i_73'], dim_hash: ['711289316', '3564785012', '814255356'] },
    chaos: { type: 'common_rare', other_id: ['i_81', 'i_82', 'i_83'], dim_hash: ['3438617436', '3502516708', '3313714244'] },
    mist: { type: 'common_rare', other_id: ['i_91', 'i_92', 'i_93'], dim_hash: ['2471442420', '132455476', '3503579852'] },
    sacrificial_knife: { type: 'common_rare', other_id: ['i_101', 'i_102', 'i_103'], dim_hash: ['1979428308', '451970588', '4188817260'] },
    bone_shard: { type: 'common_rare', other_id: ['i_141', 'i_142', 'i_143'], dim_hash: ['1908773676', '3545037916', '15453836'] },
    chaos_g: { type: 'common_rare', other_id: ['i_171', 'i_172', 'i_173'], dim_hash: ['3388706660', '3527434436', '758596260'] },
    prism: { type: 'common_rare', other_id: ['i_181', 'i_182', 'i_183'], dim_hash: ['3559934532', '277364116', '3975732044'] },
    claw: { type: 'common_rare', other_id: ['i_174', 'i_175', 'i_176'], dim_hash: ['1491574284', '2393704732', '4240144404'] },
    statuette: { type: 'common_rare', other_id: ['i_151', 'i_152', 'i_153'], dim_hash: ['352394668', '3743954164', '341144756'] },
    fungal_nucleus: { type: 'common_rare', other_id: ['i_n112062', 'i_n112063', 'i_n112064'], dim_hash: ['3338415988', '3667476428', '3245213916'] },
    chaos_s: { type: 'common_rare', other_id: ['i_n112068', 'i_n112069', 'i_n112070'], dim_hash: ['3624263596', '2462831988', '1238837980'] },
    d_prism: { type: 'common_rare', other_id: ['i_n112071', 'i_n112072', 'i_n112073'], dim_hash: ['1323847452', '707744556', '3862610052'] },
    shell: { type: 'common_rare', other_id: ['i_n112074', 'i_n112075', 'i_n112076'], dim_hash: ['1287058980', '2424696532', '2728537068'] },
    flower: { type: 'common_rare', other_id: ['i_n112077', 'i_n112078', 'i_n112079'], dim_hash: ['1643560060', '3189804116', '1166016020'] },
    tainted_water: { type: 'common_rare', other_id: ["i_n112086", "i_n112087", "i_n112088"], dim_hash: ['2692224564', '1247907484', '3089317812'] },
    core: { type: 'common_rare', other_id: ["i_n112089", "i_n112090", "i_n112091"], dim_hash: ['898921804', '2190513620', '3328017644'] },
    pocket_watch: { type: 'common_rare', other_id: ["i_n112092", "i_n112093", "i_n112094"], dim_hash: ['1504576172', '3044187156', '651341044'] },
    fin: { type: 'common_rare', other_id: ["i_n112095", "i_n112096", "i_n112097"], dim_hash: ['3306086700', '3086827388', '1023807348'] },
    hilt: { type: 'common_rare', other_id: ["i_n112098", "i_n112099", "i_n112100"], dim_hash: ['3712958284', '3816991708', '1973477156'] },
    will: { type: 'common_rare', other_id: ['i_n112107','i_n112108','i_n112109'], dim_hash: ['1572410052','2279091420','2951054716'], release: '2024-08-28' },
    ignited: { type: 'common_rare', other_id: ['i_n112110','i_n112111','i_n112112'], dim_hash: ['3979941412','1077445556','3895330420'], release: '2024-08-28' },
    secret_source: { type: 'common_rare', other_id: ['i_n112113','i_n112114','i_n112115'], dim_hash: ['2284574636','1849680204','4147918676'], release: '2024-08-28' },

    slime: { type: 'common', other_id: ['i_21', 'i_22', 'i_23'], dim_hash: ['1442515996', '2293593012', '3865019532'] },
    mask: { type: 'common', other_id: ['i_31', 'i_32', 'i_33'], dim_hash: ['461826612', '3015475460', '1726035172'] },
    scroll: { type: 'common', other_id: ['i_41', 'i_42', 'i_43'], dim_hash: ['1838237500', '2199916436', '4256206044'] },
    arrowhead: { type: 'common', other_id: ['i_51', 'i_52', 'i_53'], dim_hash: ['85550076', '2287171180', '744013132'] },
    f_insignia: { type: 'common', other_id: ['i_111', 'i_112', 'i_113'], dim_hash: ['3337386732', '2914740468', '966140916'] },
    th_insignia: { type: 'common', other_id: ['i_121', 'i_122', 'i_123'], dim_hash: ['2331730468', '1153212396', '2209041116'] },
    nectar: { type: 'common', other_id: ['i_131', 'i_132', 'i_133'], dim_hash: ['3308824804', '3355821404', '2460819340'] },
    handguard: { type: 'common', other_id: ['i_161', 'i_162', 'i_163'], dim_hash: ['3548930732', '832899108', '3514697380'] },
    spectral_husk: { type: 'common', other_id: ['i_185', 'i_186', 'i_187'], dim_hash: ['2130054892', '4256517428', '3049633276'] },
    spores: { type: 'common', other_id: ['i_n112059', 'i_n112060', 'i_n112061'], dim_hash: ['2849798340', '884678236', '358330556'] },
    red_satin: { type: 'common', other_id: ['i_n112065', 'i_n112066', 'i_n112067'], dim_hash: ['2216845460', '2568989060', '82539884'] },
    transoceanic: { type: 'common', other_id: ["i_n112080", "i_n112081", "i_n112082"], dim_hash: ['1313089108', '1145136068', '1028727868'] },
    gear: { type: 'common', other_id: ["i_n112083", "i_n112084", "i_n112085"], dim_hash: ['3384758508', '3204145100', '292782396'] },
    n_fang: { type: 'common', other_id: ['i_n112101','i_n112102','i_n112103'], dim_hash: ['4078912436','185496004','564670180'], release: '2024-08-28' },
    whistle: { type: 'common', other_id: ['i_n112104','i_n112105','i_n112106'], dim_hash: ['3715939572','1137550316','3748972940'], release: '2024-08-28' },

    dvalins_plume: { type: 'boss', other_id: 'i_461', dim_hash: '1685239844' },
    dvalins_claw: { type: 'boss', other_id: 'i_462', dim_hash: '2497474860' },
    dvalins_sigh: { type: 'boss', other_id: 'i_463', dim_hash: '3086954228' },
    tail_of_boreas: { type: 'boss', other_id: 'i_464', dim_hash: '2030091732' },
    ring_of_boreas: { type: 'boss', other_id: 'i_465', dim_hash: '2394085100' },
    spirit_locket_of_boreas: { type: 'boss', other_id: 'i_466', dim_hash: '1836033452' },
    tusk_of_monoceros_caeli: { type: 'boss', other_id: 'i_467', dim_hash: '3728851868' },
    shard_of_a_foul_legacy: { type: 'boss', other_id: 'i_468', dim_hash: '773658036' },
    shadow_of_the_warrior: { type: 'boss', other_id: 'i_469', dim_hash: '4138714844' },
    dragon_lords_crown: { type: 'boss', other_id: 'i_470', dim_hash: '2308936636' },
    bloodjade_branch: { type: 'boss', other_id: 'i_471', dim_hash: '2573932868' },
    gilded_scale: { type: 'boss', other_id: 'i_472', dim_hash: '3484301404' },
    signora_flower: { type: 'boss', other_id: 'i_480', dim_hash: '2152977356' },
    signora_wings: { type: 'boss', other_id: 'i_481', dim_hash: '4185077420' },
    signora_heart: { type: 'boss', other_id: 'i_482', dim_hash: '2165808876' },
    mudra_of_the_malefic_general: { type: 'boss', other_id: 'i_483', dim_hash: '3301367164' },
    tears_of_the_calamitous_god: { type: 'boss', other_id: 'i_484', dim_hash: '552133044' },
    the_meaning_of_aeons: { type: 'boss', other_id: 'i_485', dim_hash: '926563116' },
    puppet_strings: { type: 'boss', other_id: 'i_n113041', dim_hash: '954195700' },
    mirror_of_mushin: { type: 'boss', other_id: 'i_n113042', dim_hash: '1943200004' },
    dakas_bell: { type: 'boss', other_id: 'i_n113043', dim_hash: '3831595124' },
    worldspan_fern: { type: 'boss', other_id: 'i_n113046', dim_hash: '127661484' },
    primordial_greenbloom: { type: 'boss', other_id: 'i_n113047', dim_hash: '4261688332' },
    everamber: { type: 'boss', other_id: 'i_n113048', dim_hash: '3963682724' },
    lightless_silk_string: { type: 'boss', other_id: 'i_n113054', dim_hash: '1578050636' },
    lightless_eye_of_the_maelstrom: { type: 'boss', other_id: 'i_n113055', dim_hash: '3520191180' },
    lightless_mass: { type: 'boss', other_id: 'i_n113056', dim_hash: '2238220180' },
    fading_candle: { type: 'boss', dim_hash: '660834972' },
    silken_feather: { type: 'boss', dim_hash: '2216517604' },
    denial_and_judgment: { type: 'boss', dim_hash: '1659336452' },

    hurricane_seed: { type: 'element_2', other_id: 'i_205', dim_hash: '3449150084' },
    lightning_prism: { type: 'element_2', other_id: 'i_203', dim_hash: '3227388316' },
    basalt_pillar: { type: 'element_2', other_id: 'i_206', dim_hash: '1165077972' },
    hoarfrost_core: { type: 'element_2', other_id: 'i_204', dim_hash: '386158132' },
    everflame_seed: { type: 'element_2', other_id: 'i_201', dim_hash: '988997460' },
    cleansing_heart: { type: 'element_2', other_id: 'i_202', dim_hash: '1435804524' },
    juvenile_jade: { type: 'element_2', other_id: 'i_207', dim_hash: '4143495132' },
    crystalline_bloom: { type: 'element_2', other_id: 'i_208', dim_hash: '3851624660' },
    maguu_kishin: { type: 'element_2', other_id: 'i_210', dim_hash: '3762412924' },
    perpetual_heart: { type: 'element_2', other_id: 'i_211', dim_hash: '2070124532' },
    smoldering_pearl: { type: 'element_2', other_id: 'i_212', dim_hash: '2056318452' },
    dew_of_repudiation: { type: 'element_2', other_id: 'i_213', dim_hash: '594984684' },
    storm_beads: { type: 'element_2', other_id: 'i_214', dim_hash: '1816276316' },
    riftborn_regalia: { type: 'element_2', other_id: 'i_215', dim_hash: '2389242444' },
    dragonheirs_false_fin: { type: 'element_2', other_id: 'i_216', dim_hash: '4014873668' },
    runic_fang: { type: 'element_2', other_id: 'i_486', dim_hash: '1692924756' },
    majestic_hooked_beak: { type: 'element_2', other_id: 'i_n113036', dim_hash: '1986578756' },
    thunderclap_fruitcore: { type: 'element_2', other_id: 'i_n113037', dim_hash: '1912724692' },
    perpetual_caliber: { type: 'element_2', other_id: 'i_n113038', dim_hash: '639583268' },
    light_guiding_tetrahedron: { type: 'element_2', other_id: 'i_n113039', dim_hash: '1868454972' },
    quelled_creeper: { type: 'element_2', other_id: 'i_n113040', dim_hash: '3732304548' },
    pseudo_stamens: { type: 'element_2', other_id: 'i_n113044', dim_hash: '125566492' },
    evergloom_ring: { type: 'element_2', other_id: 'i_n113045', dim_hash: '2401980340' },
    clockwork_component_coppelia: { type: 'element_2', other_id: 'i_n113049', dim_hash: '2278720404' },
    clockwork_component_coppelius: { type: 'element_2', other_id: 'i_n113050', dim_hash: '3668451100' },
    emperors_resolution: { type: 'element_2', other_id: 'i_n113051', dim_hash: '3417328860' },
    tourbillon_device: { type: 'element_2', other_id: 'i_n113052', dim_hash: '2643139764' },
    fontemer_unihorn: { type: 'element_2', other_id: 'i_n113053', dim_hash: '1448296588' },
    water_that_failed: { type: 'element_2', other_id: 'i_n113057', dim_hash: '2446795908' },
    cloudseam_scale: { type: 'element_2', other_id: 'i_n113058', dim_hash: '2931530028' },
    golden_melody: { type: 'element_2', other_id: 'i_n113059', dim_hash: '3748772404' },
    binding_blessing: { type: 'element_2', other_id: 'i_n113064', dim_hash: '1641575548', release: '2024-08-28' },
    flamegranate: { type: 'element_2', other_id: 'i_n113065', dim_hash: '312848636', release: '2024-08-28' },

    brilliant_diamond: { type: 'element_1', other_id: ['i_301', 'i_302', 'i_303', 'i_304'], dim_hash: ['1542620268', '311931636', '3015246748', '841425532'] },
    agnidus_agate: { type: 'element_1', other_id: ['i_311', 'i_312', 'i_313', 'i_314'], dim_hash: ['2483376012', '3100999548', '3381793068', '2914841348'] },
    varunada_lazurite: { type: 'element_1', other_id: ['i_321', 'i_322', 'i_323', 'i_324'], dim_hash: ['805672532', '3993365836', '1384566356', '1417433532'] },
    nagadus_emerald: { type: 'element_1', other_id: ['i_371', 'i_372', 'i_373', 'i_374'], dim_hash: ['959469100', '2728980868', '972163988', '2238447652'] },
    vajrada_amethyst: { type: 'element_1', other_id: ['i_331', 'i_332', 'i_333', 'i_334'], dim_hash: ['1830354268', '146485284', '2323057604', '2377787012'] },
    vayuda_turqoise: { type: 'element_1', other_id: ['i_351', 'i_352', 'i_353', 'i_354'], dim_hash: ['3707625380', '2146025036', '4211103924', '995014132'] },
    shivada_jade: { type: 'element_1', other_id: ['i_341', 'i_342', 'i_343', 'i_344'], dim_hash: ['3090648604', '2226068148', '498228604', '1494673132'] },
    prithiva_topaz: { type: 'element_1', other_id: ['i_361', 'i_362', 'i_363', 'i_364'], dim_hash: ['1612219044', '401353956', '131508644', '2186134900'] },

    freedom: { type: 'talent', other_id: ['i_421', 'i_422', 'i_423'], dim_hash: ['2262214436', '3020009268', '2212011580'] },
    resistance: { type: 'talent', other_id: ['i_451', 'i_452', 'i_453'], dim_hash: ['3958753156', '2693927380', '3735432580'] },
    ballad: { type: 'talent', other_id: ['i_401', 'i_402', 'i_403'], dim_hash: ['3272646612', '3218582564', '3202197196'] },
    prosperity: { type: 'talent', other_id: ['i_441', 'i_442', 'i_443'], dim_hash: ['2565711028', '3275055188', '1927399596'] },
    diligence: { type: 'talent', other_id: ['i_411', 'i_412', 'i_413'], dim_hash: ['206988300', '2071392676', '620969700'] },
    gold: { type: 'talent', other_id: ['i_431', 'i_432', 'i_433'], dim_hash: ['3835242828', '266512684', '2661153132'] },
    transience: { type: 'talent', other_id: ['i_406', 'i_407', 'i_408'], dim_hash: ['2569352716', '4164589940', '2381438580'] },
    elegance: { type: 'talent', other_id: ['i_416', 'i_417', 'i_418'], dim_hash: ['2962100916', '4156724932', '2852490284'] },
    light: { type: 'talent', other_id: ['i_426', 'i_427', 'i_428'], dim_hash: ['2661352372', '2811001012', '3803357620'] },
    admonition: { type: 'talent', other_id: ['i_n104329', 'i_n104330', 'i_n104331'], dim_hash: ['1901318836', '886644404', '1067170380'] },
    ingenuity: { type: 'talent', other_id: ['i_n104332', 'i_n104333', 'i_n104334'], dim_hash: ['965705772', '778263340', '3292554300'] },
    praxis: { type: 'talent', other_id: ['i_n104335', 'i_n104336', 'i_n104337'], dim_hash: ['3345548268', '1803874988', '1186858772'] },
    fairness: { type: 'talent', other_id: ["i_n104338", "i_n104339", "i_n104340"], dim_hash: ['2877204556', '389729692', '1441285348'] },
    justice: { type: 'talent', other_id: ["i_n104341", "i_n104342", "i_n104343"], dim_hash: ['1406380412', '4100325052', '413472740'] },
    order: { type: 'talent', other_id: ["i_n104344", "i_n104345", "i_n104346"], dim_hash: ['3505049628', '261642860', '278225452'] },
    contention: { type: 'talent', other_id: ['i_n104347','i_n104348','i_n104349'], dim_hash: ['181933276','279513804','2186573124'], release: '2024-08-28' },
    kindling: { type: 'talent', other_id: ['i_n104350','i_n104351','i_n104352'], dim_hash: ['522559596','2720738460','3127398652'], release: '2024-08-28' },
    conflict: { type: 'talent', other_id: ['i_n104353','i_n104354','i_n104355'], dim_hash: ['1330781756','621148708','2706579636'], release: '2024-08-28' },

    crown: { type: 'special', other_id: 'i_491', dim_hash: '2454933108' },

    decarabian: { type: 'wam', other_id: ['i_501', 'i_502', 'i_503', 'i_504'], dim_hash: ['2567593812', '964863604', '1852199700', '2734083860'] },
    boreal_wolf: { type: 'wam', other_id: ['i_521', 'i_522', 'i_523', 'i_524'], dim_hash: ['1988951604', '3946196844', '3293824324', '1910458284'] },
    dandelion_gladiator: { type: 'wam', other_id: ['i_541', 'i_542', 'i_543', 'i_544'], dim_hash: ['1746118620', '2310635516', '3908879748', '4141994924'] },
    guyun: { type: 'wam', other_id: ['i_511', 'i_512', 'i_513', 'i_514'], dim_hash: ['2979991860', '3800788188', '2356214676', '1100805700'] },
    mist_veiled_elixer: { type: 'wam', other_id: ['i_531', 'i_532', 'i_533', 'i_534'], dim_hash: ['3020828124', '3484430476', '2143222900', '1879353236'] },
    aerosiderite: { type: 'wam', other_id: ['i_551', 'i_552', 'i_553', 'i_554'], dim_hash: ['387168756', '1391788468', '145783836', '2698984332'] },
    sea_branch: { type: 'wam', other_id: ['i_561', 'i_562', 'i_563', 'i_564'], dim_hash: ['871205868', '1256537812', '1039176244', '1552632404'] },
    narukami: { type: 'wam', other_id: ['i_571', 'i_572', 'i_573', 'i_574'], dim_hash: ['148841796', '2954572564', '831056940', '449374556'] },
    mask_w: { type: 'wam', other_id: ['i_581', 'i_582', 'i_583', 'i_584'], dim_hash: ['2900857628', '3536791340', '3486743316', '1747026276'] },
    talisman: { type: 'wam', other_id: ['i_n114037', 'i_n114038', 'i_n114039', 'i_n114040'], dim_hash: ['1730762740', '3230177356', '3177631348', '2877776164'] },
    oasis_gardens: { type: 'wam', other_id: ['i_n114041', 'i_n114042', 'i_n114043', 'i_n114044'], dim_hash: ['3952230084', '3957624372', '100335260', '1198987156'] },
    scorching_might: { type: 'wam', other_id: ['i_n114045', 'i_n114046', 'i_n114047', 'i_n114048'], dim_hash: ['3558261580', '3735544220', '1697960908', '3000058380'] },
    ancient_chord: { type: 'wam', other_id: ["i_n114049", "i_n114050", "i_n114051", "i_n114052"], dim_hash: ['2445333860', '991486964', '2572043756', '362996932'] },
    dewdrop: { type: 'wam', other_id: ["i_n114053", "i_n114054", "i_n114055", "i_n114056"], dim_hash: ['454524596', '2142067084', '2470518764', '4236140148'] },
    pristine_sea: { type: 'wam', other_id: ["i_n114057", "i_n114058", "i_n114059", "i_n114060"], dim_hash: ['3855262532', '702605524', '493758764', '1188621748'] },
    bs_heart: { type: 'wam', other_id: [], dim_hash: ['2738390100','2544947124','2321774348','189628396'], release: '2024-08-28' },
    delirious: { type: 'wam', other_id: [], dim_hash: ['2495009204','980700500','2790178484','2420204052'], release: '2024-08-28' },
    night_wind: { type: 'wam', other_id: [], dim_hash: ['1136126652','2996994716','947183444','1522196276'], release: '2024-08-28' },

    wolfhook: { type: 'local', other_id: 'i_613', dim_hash: '295703942' },
    valberry: { type: 'local', other_id: 'i_610', dim_hash: '161719854' },
    cecilia: { type: 'local', other_id: 'i_601', dim_hash: '661705734' },
    windwheel_aster: { type: 'local', other_id: 'i_612', dim_hash: '383388302' },
    philanemo_mushroom: { type: 'local', other_id: 'i_607', dim_hash: '873400996' },
    small_lamp_grass: { type: 'local', other_id: 'i_609', dim_hash: '144892988' },
    calla_lily: { type: 'local', other_id: 'i_600', dim_hash: '634158982' },
    dandelion_seed: { type: 'local', other_id: 'i_603', dim_hash: '856244868' },
    jueyun_chili: { type: 'local', other_id: 'i_605', dim_hash: '22100332' },
    noctilucous_jade: { type: 'local', other_id: 'i_606', dim_hash: '2708851374' },
    silk_flower: { type: 'local', other_id: 'i_608', dim_hash: '22655006' },
    glaze_lilly: { type: 'local', other_id: 'i_604', dim_hash: '432382212' },
    qingxin: { type: 'local', other_id: 'i_614', dim_hash: '345337750' },
    starconch: { type: 'local', other_id: 'i_663', dim_hash: '1463427644' },
    violetgrass: { type: 'local', other_id: 'i_611', dim_hash: '330229718' },
    cor_lapis: { type: 'local', other_id: 'i_602', dim_hash: '1168046622' },
    clearwater_jade: { type: 'local', other_id: 'i_n101240', dim_hash: '712071822' },
    onikabuto: { type: 'local', other_id: 'i_677', dim_hash: '685909559' },
    sakura_bloom: { type: 'local', other_id: 'i_678', dim_hash: '2112409230' },
    crystal_marrow: { type: 'local', other_id: 'i_679', dim_hash: '307506948' },
    dendrobium: { type: 'local', other_id: 'i_680', dim_hash: '354894748' },
    naku_weed: { type: 'local', other_id: 'i_681', dim_hash: '734391582' },
    sea_ganoderma: { type: 'local', other_id: 'i_675', dim_hash: '83818054' },
    sango_pearl: { type: 'local', other_id: 'i_685', dim_hash: '1695071014' },
    tenkumo_fruit: { type: 'local', other_id: 'i_686', dim_hash: '2782194950' },
    fluorescent_fungus: { type: 'local', other_id: 'i_688', dim_hash: '550470902' },
    rukkhashava: { type: 'local', other_id: 'i_n101213', dim_hash: '225599940' },
    padisarah: { type: 'local', other_id: 'i_n101214', dim_hash: '200162852' },
    nilotpala: { type: 'local', other_id: 'i_n101215', dim_hash: '774700980' },
    kalpalata: { type: 'local', other_id: 'i_n101217', dim_hash: '221386750' },
    redcrest: { type: 'local', other_id: 'i_n101220', dim_hash: '3448878900' },
    sand_grease_pupa: { type: 'local', other_id: 'i_n101222', dim_hash: '755704558' },
    mourning_flower: { type: 'local', other_id: 'i_n101223', dim_hash: '940244014' },
    trishiraite: { type: 'local', other_id: 'i_n101224', dim_hash: '239145148' },
    scarab: { type: 'local', other_id: 'i_n101225', dim_hash: '2460531480' },
    beryl_conch: { type: 'local', other_id: 'i_n101232', dim_hash: '929825734' },
    romaritime_flower: { type: 'local', other_id: 'i_n101233', dim_hash: '418492766' },
    lumidouce_bell: { type: 'local', other_id: 'i_n101235', dim_hash: '205491460' },
    rainbow_rose: { type: 'local', other_id: 'i_n101236', dim_hash: '2386701060' },
    lumitoile: { type: 'local', other_id: 'i_n101237', dim_hash: '316560590' },
    lakelight_lily: { type: 'local', other_id: 'i_n101238', dim_hash: '47844060' },
    subdetection_unit: { type: 'local', other_id: 'i_n101239', dim_hash: '477024520' },
    first_dewdrop: { type: 'local', other_id: 'i_n101240', dim_hash: '1023874206' },
    sprayfeather: { type: 'local', other_id: 'i_n101247', dim_hash: '4108433756', release: '2024-08-28' },
    chrysanthemum: { type: 'local', other_id: '', dim_hash: '2302571844', show: '2024-08-28', release: '2024-08-28' },
    quenepa: { type: 'local', other_id: 'i_n101249', dim_hash: '2670150404', release: '2024-08-28' },
    saurian_succulent: { type: 'local', other_id: 'i_n101250', dim_hash: '19028052', release: '2024-08-28' },
};
