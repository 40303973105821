export default {
    wl: {
        0: { xp: 25000,  mora: 12000, element_2: 1.56 },
        1: { xp: 38500,  mora: 20000, element_2: 1.60 },
        2: { xp: 52500,  mora: 28000, element_2: 1.66 },
        3: { xp: 67500,  mora: 36000, element_2: 1.86 },
        4: { xp: 82500,  mora: 44000, element_2: 2.00 },
        5: { xp: 102500, mora: 52000, element_2: 2.19 },
        6: { xp: 122500, mora: 60000, element_2: 2.38 },
        7: { xp: 122500, mora: 60000, element_2: 2.55 },
        8: { xp: 122500, mora: 60000, element_2: 2.56 },
        9: { xp: 122500, mora: 60000, element_2: 3.00 },
    },
    ar: {
         0: { boss: 0.00, talent: [0.00, 0.00,  0.00], wam: [0.0,  0.0,  0.0,  0.00] },
        16: { boss: 0.00, talent: [0.00, 0.00,  0.00], wam: [4.8,  0.0,  0.0,  0.00] },
        21: { boss: 0.00, talent: [0.00, 0.00,  0.00], wam: [2.7,  8.7,  0.0,  0.00] },
        27: { boss: 0.00, talent: [3.18, 0.00,  0.00], wam: [2.7,  8.7,  0.0,  0.00] },
        28: { boss: 0.00, talent: [2.55, 5.55,  0.00], wam: [2.7,  8.7,  0.0,  0.00] },
        30: { boss: 0.00, talent: [2.55, 5.55,  0.00], wam: [2.2, 10.6, 12.4,  0.00] },
        36: { boss: 0.00, talent: [1.79, 7.79,  0.00], wam: [2.2, 10.6, 12.4,  0.00] },
        40: { boss: 1.00, talent: [1.79, 7.79,  0.00], wam: [2.2,  9.4, 14.8, 16.69] },
        45: { boss: 1.55, talent: [2.21, 8.06, 10.22], wam: [2.2,  9.4, 14.8, 16.69] },
        50: { boss: 2.10, talent: [2.21, 8.06, 10.22], wam: [2.2,  9.4, 14.8, 16.69] },
    }
}
