<template>
    <div class="text-white">
        <h1 class="text-xl font-bold">{{ $t('characters') }}</h1>
        <div class="mt-4 flex items-center">
            <div class="sm:w-32">
                <characters-modal>
                    <template v-slot:trigger>
                        <div class="bg-blue-600/60 hover:bg-blue-600 font-semibold text-sm rounded-lg py-2 px-3 shadow hover:shadow-lg w-full sm:w-32 transition-all">
                            {{ $t('add') }}
                        </div>
                    </template>
                </characters-modal>
            </div>

            <div class="ml-2 h-full">
                <button class="rounded-l p-2 h-full" :class="mode === 'vertical' ? 'bg-yellow-500' : 'bg-gray-700'" @click="mode = 'vertical'">
                    <view-grid-icon class="w-4"/>
                </button>
                <button class="rounded-r p-2 h-full" :class="mode === 'horizontal' ? 'bg-yellow-500' : 'bg-gray-700'" @click="mode = 'horizontal'">
                    <view-list-icon class="w-4"/>
                </button>
            </div>
        </div>

        <div class="mt-4">
            <div class="font-semibold uppercase text-sm mb-1">{{ $t('filter') }}</div>

            <div class="glass rounded-lg px-3 inline-flex items-center justify-start flex-col md:flex-row">
                <object-filter
                    icon="star-icon"
                    :colors="['text-legendary-400','text-epic-400']"
                    :options="[5, 4]"
                    @filter="v => filter('tier', v)"
                    :active-item="filters.tier"
                    divider
                />
                <object-filter
                    type="elements"
                    :options="elements"
                    @filter="v => filter('element', v)"
                    :active-item="filters.element"
                    divider
                />
                <object-filter
                    type="weapons/types"
                    :options="weaponTypes"
                    @filter="v => filter('weapon', v)"
                    :active-item="filters.weapon"
                    divider
                />
                <object-filter
                    icon="eye-icon"
                    :options="['toggle_inactive']"
                    @filter="hideInactive = !hideInactive"
                    :is-active="!hideInactive"
                    divider
                />
                <div class="relative flex items-center mb-2 md:mb-0">
                    <input type="text" :placeholder="$t('search')"
                        class="bg-gray-700/40 border-none rounded-md w-full max-w-64 pr-8" v-model="filters.search" @keyup.esc="filters.search = ''"
                    >
                    <button v-if="filters.search" class="absolute right-0 mr-2" @click="filters.search = ''">
                        <x-icon class="w-5 h-5"/>
                    </button>
                </div>
            </div>

            <ad class="mt-5"/>

            <div v-if="filteredCharacters.length"
                class="mt-4"
                :class="mode === 'vertical' ? 'flex flex-wrap -mx-2 sm:-mx-3 justify-center sm:justify-start' :
                    'grid 2xl:grid-cols-2 gap-y-2 gap-x-3'"
            >
                <div
                    v-show="!hideInactive || filters.search.trim() || !inactive[character]"
                    v-for="character in filteredCharacters"
                    :key="`character-${character}`"
                    class="shadow-md flex"
                    :class="{ 'm-2 sm:m-3': mode === 'vertical' }"
                >
                    <characters-modal :editing="character" w-full>
                        <template v-slot:trigger>
                            <component :is="`character-card-${mode}`" :selected="character" :class="{ 'opacity-50 filter-grayscale': inactive[character] }"/>
                        </template>
                    </characters-modal>
                </div>
            </div>

            <div v-else class="font-semibold text-xl mt-4 text-gray-200 opacity-75">
                {{ $t('no_characters') }}
            </div>
        </div>
    </div>

    <ad class="mt-5" bottom v-if="filteredCharacters.length >= 4"/>
</template>

<script>
import Ad from '@/components/Ad.vue'
import ObjectFilter from '@/common/components/ObjectFilter'
import CharactersModal from '@/components/CharactersModal.vue'
import CharacterCardHorizontal from '@/components/CharacterCardHorizontal.vue'
import CharacterCardVertical from '@/components/CharacterCardVertical.vue'

export default {
    components: {
        Ad, ObjectFilter, CharactersModal, CharacterCardHorizontal, CharacterCardVertical
    },

    data () {
        return {
            search: '',
            filters: {
                search: '',
                attribute: null,
                style: null,
                tier: null,
            },
            hideInactive: JSON.parse(localStorage.getItem('charactersHideInactive')) || false
        }
    },

    watch: {
        hideInactive (value) { localStorage.setItem('charactersHideInactive', value) }
    },

    computed: {
        mode: {
            get () { return this.settings.characters_mode },
            set (value) { this.setSetting('characters_mode', value) }
        },

        filteredCharacters () {
            return Object.keys(this.characters).concat(Object.keys(this.customs))
                .filter(c => {
                    return (!this.customs[c] || this.customs[c].custom == 'character')
                        && (!this.filters.tier || this.filters.tier === (this.characters[c] || this.customs[c]).tier)
                        && (!this.filters.element || this.filters.element === (this.characters[c] || this.customs[c]).element)
                        && (!this.filters.weapon || this.filters.weapon === (this.characters[c] || this.customs[c]).weapon)
                        && (this.customs[c] || this.goals.find(g => g.character && g.character === c))
                        && (!this.filters.search || (this.customs[c]?.name || this.$t(c)).toLowerCase().includes(this.filters.search.toLowerCase()))
                })
                .sort((a, b) => (this.customs[a]?.name || this.$t(a)).localeCompare(this.customs[b]?.name || this.$t(b)))
        }
    },

    methods: {
        filter (type, value) {
            if (this.filters[type] === value) this.filters[type] = null
            else this.filters[type] = value
        },
    }
}
</script>
