<template>
    <div class="flex flex-col space-y-1">
        <event-task-line
            :event="event"
            v-for="event in filteredEvents"
            :key="`permanent-${event.key}`"
        />
    </div>
</template>

<script>
import EventTaskLine from './EventTaskLine.vue'
import { useEvents } from '../composables/useEvents'

export default {
    components: { EventTaskLine },

    setup () {
        return useEvents()
    },

    props: {
        showAll: Boolean,
        events: Array,
    },

    computed: {
        filteredEvents () {
            if (this.showAll) return this.events

            return this.events.filter(e => !e.future)
        }
    }
}
</script>
