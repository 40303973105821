<template>
    <div class="flex glass rounded-2xl relative h-20 overflow-hidden" :class="event.characters && 'h-auto && sm:h-20 sm:col-span-2 2xl:col-span-1'">
        <div class="font-semibold text-sm rounded-md px-2 py-[.1rem] absolute top-0 right-0 m-2 shadow" :class="diffClass">
            {{ diffText }}
        </div>

        <div class="w-20 h-full flex items-center justify-center flex-shrink-0">
            <img :src="event.img" v-if="event.img" class="pointer-events-none object-cover min-h-[5rem] w-full h-full rounded-2xl"/>
            <img :src="$url(`general/${wish}.png`)" v-else-if="event.characters" class="opacity-80 w-full h-full p-4"/>
        </div>

        <div class="py-2 px-3 leading-tight flex flex-col items-start justify-center">
            <span v-if="Object.keys(typeTags).includes(event.type)"
                class="text-xs bg-purple-700/60 rounded-lg px-2 py-1 text-nowrap font-semibold"
            >{{ typeTags[event.type] }}</span>

            <a class="font-bold text-sm md:text-base mr-16 tracking-tight"
                :href="event.hoyo && `https://www.hoyolab.com/article/${event.hoyo}`"
                :class="event.hoyo && 'text-purple-100 hover:underline'"
                target="_blank"
            >
                {{ event.characters ? $t('event_wishes') : event[`name_${locale}`] }} <external-link-icon class="inline w-4 h-4" v-if="event.hoyo"/>
            </a>

            <div v-if="event.characters || event.weapons || event.cones" class="flex items-start gap-1 mt-2 sm:my-1 flex-wrap">
                <template v-if="event.characters">
                    <template v-for="character in event.characters">
                        <div class="relative" v-if="characters[character] && isFuture(characters[character].show)" v-tippy="$t(character)" :key="character">
                            <div class="w-10 h-10 overflow-hidden bg-gradient-to-br rounded-full" :class="darkTierClass[characters[character].tier]">
                                <img :src="`${characterIconPath}${character}.png`" class="w-full h-full">
                            </div>
                            <div class="bg-green-700 absolute-tag !left-auto !right-0 !-m-1 !text-xs">{{ $t('new')}}</div>
                        </div>

                        <characters-modal :editing="character" v-else-if="characters[character]" :key="`modal-${character}`">
                            <template v-slot:trigger>
                                <div class="relative" v-tippy="$t(character)">
                                    <div class="w-10 h-10 overflow-hidden bg-gradient-to-br rounded-full" :class="darkTierClass[characters[character].tier]">
                                        <img :src="`${characterIconPath}${character}.png`" class="w-full h-full">
                                    </div>
                                    <div class="bg-green-700 absolute-tag !left-auto !right-0 !-m-1 !text-xs" v-if="isFutureOrNew(characters[character].release)">{{ $t('new')}}</div>
                                </div>
                            </template>
                        </characters-modal>
                    </template>
                </template>

                <template v-if="event[weaponCollectionName]">
                    <router-link to="/weapons" v-for="w in event[weaponCollectionName]" :key="`link-${w}`">
                        <div class="relative" v-if="weaponCollection[w]" v-tippy="$t(w)">
                            <div class="w-10 h-10 overflow-hidden relative bg-gradient-to-br rounded-full" :class="darkTierClass[weaponCollection[w].tier]" >
                                <img :src="`${weaponIconPath}${w}.png`" class="w-full h-full object-contain">
                            </div>
                            <div class="bg-green-700 absolute-tag !left-auto !right-0 !-m-1 !text-xs" v-if="isFutureOrNew(weaponCollection[w].release)">{{ $t('new')}}</div>
                        </div>
                    </router-link>
                </template>

            </div>
        </div>
    </div>
</template>

<script>
import CharactersModal from '@/components/CharactersModal'

export default {
    components: { CharactersModal },

    props: {
        event: Object,
        game: String
    },

    data () {
        return {
            typeTags: {
                'checkin': 'Check-In',
                'tcg': 'TCG'
            },
            wish: {
                gi: 'wish',
                hsr: 'warp',
                zzz: 'search'
            }[this.game],
            weaponCollectionName: {
                gi: 'weapons',
                hsr: 'cones',
                zzz: 'weapons'
            }[this.game],
            characterIconPath: {
                gi: `/img/characters/select/`,
                hsr: `/img/characters/`,
                zzz: `/img/characters/circle/`,
            }[this.game],
            weaponIconPath: {
                gi: `/img/weapons/`,
                hsr: `/img/cones/`,
                zzz: `/img/weapons/`,
            }[this.game]
        }
    },

    computed: {
        weaponCollection () {
            return this[this.weaponCollectionName]
        },

        locale () {
            return this.$i18n.locale == 'pt' ? 'pt' : 'en'
        },

        eventTime () {
            if (!this.event.start) return null

            return {
                time: this.event.started ? this.event.end : this.event.start,
                global: this.event.started ? this.event.globalEnd : this.event.globalStart
            }
        },

        diff () {
            if (!this.eventTime) return null

            return (this.eventTime.global ? this.time : this.serverTime).diff(this.eventTime.time, 'hour') * -1
        },

        diffText () {
            if (!this.eventTime) return this.$t('soon')

            if (this.diff < 24) return `${this.diff}h`

            let days = Math.floor(this.diff / 24)
            let hours = this.diff % 24

            if (hours < 1) return `${days}d`

            return `${days}d ${hours}h`
        },

        diffClass () {
            if (!this.eventTime) return 'bg-gray-600'

            if (!this.event.started) return 'bg-blue-800'

            if (this.diff > 24*3) return 'bg-green-600'

            if (this.diff > 24) return 'bg-yellow-600'

            return 'bg-red-600'
        }
    }
}
</script>
