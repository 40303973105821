<template>
    <div class="text-white w-full">
        <div class="bg-gray-800/80 shadow w-full rounded-lg py-6 px-6 flex flex-col items-center text-center">
            <img class="w-72 mb-4" :src="`${url}img/logo.svg`">
            <div class="mt-4 leading-tight text-base sm:text-lg tracking-wide">
                <strong>{{ $t('tagline') }}</strong>
                <p class="mt-2" v-html="$t('introduction')"></p>
            </div>
            <p class="mt-6 flex items-center bg-green-500 rounded-xl py-2 pl-2 pr-3 text-sm tracking-tight font-semibold leading-none md:hidden">
                <span class="mr-2 bg-gray-500 opacity-75 rounded-full p-1"><menu-icon class="w-5"/></span>
                {{ $t('menu_exp') }}
            </p>
        </div>

        <seelie-selector active="gi"/>

        <div class="mt-3">
            <events game="gi"/>
        </div>

        <ad class="mt-5"/>

        <!-- <div class="xl:hidden mt-3">
            <news/>
        </div> -->

        <div class="mt-3">
            <birthdays/>
        </div>

        <!-- <button class="bg-gray-700 w-full text-left rounded-lg py-6 px-6 mt-6 flex items-center" @click="isDonateModalVisible = true">
            <img :src="`${url}img/stickers/1-mora.png`" class="h-16 mr-4"/>
            <div>
                <p class="uppercase font-semibold">Donate</p>
                <p class="mt-1 text-lg leading-tight">Click here to see how you can help.</p>
            </div>
        </button> -->

        <p class="mt-6 ml-1 uppercase font-semibold">{{ $t('changelog') }}</p>

        <div class="bg-gray-700/70 w-full font-semibold rounded-lg py-3 px-4 mt-2">
            {{ $t('disclaimer_future_content') }}
        </div>

        <div class="bg-gray-700/30 mt-2 rounded-lg py-3 px-6">
            <div v-for="(c, i) in slicedChangelog" :key="`changelog-${i}`">
                <div class="font-bold mt-3 mb-1 bg-gray-700/50 inline-block px-3 py-1 rounded-full text-sm">
                    <template v-if="locale == 'en'">{{ dayjs(c.date).format('MMM D, YYYY') }}</template>
                    <template v-else>{{ dayjs(c.date).locale('pt').format('DD/MM/YYYY') }}</template>
                </div>

                <div v-for="(entry, e) in c.entries" :key="`changelog-${i}-${e}`" class="my-1 flex" :class="{ 'font-semibold': entry.bold }">
                    <span>{{ entry.emoji }}</span>
                    <span class="ml-1" v-html="entry.entry"></span>
                </div>
            </div>
        </div>

        <button
            class="bg-gray-700/70 font-semibold w-full mt-2 rounded-lg py-3 px-6 text-left focus:outline-none"
            @click="showMore"
        >
            {{ changelogPage*changelogPerPage < filteredChangelog.length ? $t('show_more') : $t('show_less') }}
        </button>
    </div>
</template>

<script>
import Ad from '@/components/Ad.vue'
import Birthdays from '@/components/Birthdays'
import Events from '@/common/components/Events'
import changelog from '@/database/changelog'
import SeelieSelector from '@/common/components/SeelieSelector'

export default {
    components: { Ad, Birthdays, Events, SeelieSelector },

    data () {
        return {
            changelog,
            isLoadingChangelog: false,
            fullChangelogLoaded: false,
            changelogPage: 1,
            changelogPerPage: 4,
            changelogTypes: {
                'features':     { order: 0, emoji: '✨', class: 'text-purple-400' }, // new feature 🎉🌟
                'characters':   { order: 1, emoji: '👤', class: 'text-blue-400' }, // new character
                'weapons':      { order: 2, emoji: '⚔️', class: 'text-pink-400' }, // new weapon
                'items':        { order: 3, emoji: '📦', class: 'text-orange-400' }, // new item
                'achievements': { order: 4, emoji: '🏆', class: 'text-yellow-400' }, // new achievement
                'events':       { order: 5, emoji: '📅', class: 'text-green-400' }, // events
                'tweaks':       { order: 6, emoji: '⚙️', class: '' }, // other tweaks 🛠️
                'bugs':         { order: 7, emoji: '🐛', class: '' }, // bug fixes 🔧
                '':             { order: 8, emoji: '', class: '' }, // bug fixes 🔧
            }
        }
    },

    computed: {
        locale () {
            return this.$i18n.locale == 'pt' ? 'pt' : 'en'
        },

        filteredChangelog () {
            return this.changelog.filter(entry => entry[1] == this.locale)
                .sort((a, b) => this.changelogTypes[a[2]].order - this.changelogTypes[b[2]].order)
                .sort((a, b) => {
                    return this.dayjs(b[0]).diff(a[0])
                }).reduce((cl, entry) => {
                    let index = cl.findIndex(c => c.date == entry[0])

                    if (index == -1) index = (cl.push({ date: entry[0], entries: [] })) - 1

                    cl[index].entries.push({
                        entry: this.formatEntry(entry[3], entry[2]),
                        type: entry[2],
                        bold: !this.changelogTypes[entry[2]].order,
                        emoji: this.changelogTypes[entry[2]].emoji
                    })
                    return cl
                }, [])
        },

        slicedChangelog () {
            return this.filteredChangelog.slice(0, this.changelogPage * this.changelogPerPage)
        }
    },

    methods: {
        formatEntry (text, type) {
            return text.replaceAll(/\[bold:(.+)\]/gi, (_, found) => `<span class="font-semibold ${this.changelogTypes[type].class}">${found}</span>`)
        },

        async showMore () {
            if (this.isLoadingChangelog) return

            if (!this.fullChangelogLoaded) {
                this.isLoadingChangelog = true
                const data = await import('@/database/changelog-full')
                this.changelog = this.changelog.concat(data.default)
                // console.log(this.changelog)
                this.fullChangelogLoaded = true
                this.isLoadingChangelog = false
            }

            if (this.changelogPage * this.changelogPerPage < this.filteredChangelog.length) this.changelogPage++
            else this.changelogPage = 1
        }
    }
}
</script>
